//mask2
(function($) {
	$.fn.extend({
		mask2: function(value) {
			this.data('mask',value);
			var maxlength=this.attr('maxlength');
			if((!maxlength || maxlength==-1) && value){
				this.attr('maxlength',value.length);
			}
			this.bind('blur change keyup click keypress',function(){
				var mask=$(this).data('mask');
				if(mask){
					var lengthM=0;
					mask=mask.split('');
					$(mask).each(function(i,n){
						if(jQ.isNumeric(n))lengthM++;
					});
					var tmp = $(this).val().split('');
					var value = [];
					$(tmp).each(function(i, n) {
						if (jQ.isNumeric(n))
							value.push(n);
					});
					var lengthV = value.length;

					if (lengthM == lengthV) {
						var result = [];
						var index = 0;
						$(mask).each(function(i, n) {
							if (n == 9) {
								result.push(value[index]);
								index++;
							} else
								result.push(n);
						});
						result = result.join('');
						if (result != $(this).val()) {
							$(this).val(result);
						}
					} else {
						if ($(this).parent().find('.error').length) {
							//console.log('222');
						}
					}
				}
			});
			return this;
		}
	});
	$.fn.extend({
		unmask2 : function() {
			$(this).removeData('mask');
			return this;
		}
	});
})(jQuery);