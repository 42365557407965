/*
 * SCROLLTO
 */

(function($) { //pgrzech
	$.fn.scrollTo = function(x, speed, offsetTop) {
		speed = speed ? speed : 300;
		offsetTop = offsetTop ? offsetTop : 0;
		var scrollPoint;
		var stickyHeight = $(".sticky #top").outerHeight() || 0;
		var topBarHeight = 0; //$("#top-bar").outerHeight() || 0;
		var sticky = stickyHeight != 0 ? true : false;
		var productMenuHeight = $('#menu-product').outerHeight() || $('#product-nav').outerHeight() || 0;
		var productMenu = productMenuHeight == 0 ? true : false;

		function calculateScrollPoint(point) {
			if (point >= 0) {
				scrollPoint = point;
			} else if (point instanceof jQuery) {
				try {
					scrollPoint = point.offset().top;
				} catch (e) {}
			} else {
				try {
					scrollPoint = $(point).offset().top;
				} catch (e) {}
			}
		}
		calculateScrollPoint(x);
		if (scrollPoint >= 0) {
			$("body,html").animate({
				scrollTop: scrollPoint - stickyHeight - topBarHeight - productMenuHeight - offsetTop + "px"
			}, speed, function() {
				calculateScrollPoint(x);
				productMenuHeight = $('#menu-product').outerHeight() || $('#product-nav').outerHeight() || 0;
				stickyHeight = $(".sticky #top").outerHeight() || 0;

				$("body,html").animate({
					scrollTop: scrollPoint - stickyHeight - topBarHeight - productMenuHeight - offsetTop + "px"
				}, 0);
			});
		}

	};
	$.scrollTo = $(window).scrollTo;
}(jQuery));