$.fn.productSlider = function(options) {
	/*Parent listy musi mieć ustawioną wysokość i szerokość (rozmiar widoku), overflow hidden
	Lista musi miec overflow hidden, left 0, position absolute
	Spacing między li musi być paddingiem */
	var $self = $(this);
	var $list = $self.find('ul').first();
	var $container = $list.parent();
	var $nextButton, $previousButton;
	var carouselElementShift, possibleElementShifts;
	var defaults = {
		'carouselSpeed': 800,
		'maxElementShift': 3
	};
	return this.each(function() {
		var resizeDelay;
		var settings = $.extend({}, defaults, options);
		function init() {
			setElementVariables();
			setWidth();
			createElements();
			toggleElementsVisibility();
			events();
		}
		function animate(option) {
			disableArrows();
			var itemWidth = settings.item.outerWidth();
			//ilość widocznych elementów
			var visibleElements = $container.outerWidth() / itemWidth;
			//maksymalne przesunięcie karuzeli w px równe ilości niewidocznych elementów * szerokość jednego elementu
			var maxPixelShift = (settings.item.length - visibleElements) * itemWidth;
			//co ile elementów max przewijamy
			var maxElementShift = settings.maxElementShift;
			//obecne przesunięcie
			var position = parseInt($list.css('left'), 10);
			if(option === 'right') {
				//ile jest dostępnych całych przesunięć
				var possibleElementShifts = -position / itemWidth;
				//jeśli przesunięć można zrobić więcej niż standardowa ilość
				if(possibleElementShifts > maxElementShift) {
					var carouselElementShift = maxElementShift;
				} else if(possibleElementShifts === maxElementShift){
					var carouselElementShift = maxElementShift;
					hidePreviousButton();
				} else{
					var carouselElementShift = possibleElementShifts;
					hidePreviousButton();
				}
				position += itemWidth * carouselElementShift;
				showNextButton();
			} else if(option === 'left'){
				//ile jest dostępnych całych przesunięć
				var possibleElementShifts = (maxPixelShift + position) / itemWidth;
				//jeśli przesunięć można zrobić więcej niż standardowa ilość
				if(possibleElementShifts > maxElementShift) {
					var carouselElementShift = maxElementShift;
				} else if(possibleElementShifts === maxElementShift){
					var carouselElementShift = maxElementShift;
					hideNextButton();
				} else {
					var carouselElementShift = possibleElementShifts;
					hideNextButton();
				}
				position -= itemWidth * carouselElementShift;
				showPreviousButton();
			} else if(option === 'resize'){
				//przy zmianie rozmiaru okna zerujemy karuzelę
				position = 0;
				//chowamy strzałkę w Lewo
				hidePreviousButton();
				//ile jest dostępnych całych przesunięć
				var possibleElementShifts = maxPixelShift / itemWidth;
				//liczymy czy strzałka w prawo jest potrzebna
				if(possibleElementShifts <= 0) {
					hideNextButton();
				} else {
					showNextButton();
				}
			}
			//animacja
			$list.animate({
				left: position
			}, settings.carouselSpeed, function(){
				enableArrows();
				$(document).trigger('lazyLoad:check');
			});
		}
		function showPreviousButton() {
			$container.removeClass('button-previous-hidden');
		}
		function hidePreviousButton() {
			$container.addClass('button-previous-hidden');
		}
		function showNextButton() {
			$container.removeClass('button-next-hidden');
		}
		function hideNextButton() {
			$container.addClass('button-next-hidden');
		}
		function disableArrows() {
			$previousButton.addClass('button-disabled');
			$nextButton.addClass('button-disabled');
		}
		function enableArrows() {
			$previousButton.removeClass('button-disabled');
			$nextButton.removeClass('button-disabled');
		}
		function createElements() {
			$('<button class="js-product-slider-previous product-slider-previous"></button>').insertBefore($list);
			$('<button class="js-product-slider-next product-slider-next"></button>').insertAfter($list);
			$container.addClass('product-slider button-previous-hidden button-next-hidden');
		}
		function toggleElementsVisibility() {
			if($list.outerWidth() > $container.outerWidth()) {
				$container.removeClass('button-next-hidden');
			}
		}
		// ustawienie szerokości widocznej listy
		function setWidth() {
			var width = settings.item.length * settings.item.outerWidth();
			$list.css('width', width);
		}
		function setElementVariables() {
			settings.slider = $list;
			settings.item = $list.children('li');
		}
		function recalculateSlider(){
			setElementVariables();
			animate('resize');
			clearTimeout(resizeDelay);
		}
		function events() {
			$previousButton = $list.siblings('.js-product-slider-previous');
			$nextButton = $list.siblings('.js-product-slider-next');
			$previousButton.on('click', function(){
				animate('right');
			});
			$nextButton.on('click', function(){
				animate('left');
			});
			if(typeof $.fn.swipe == 'function' && !$container.is('.button-next-hidden.button-previous-hidden')){
				settings.slider.swipe({
					swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
						animate(direction);
					},
					excludedElements: "label, button, input, select, textarea, .noSwipe",
					allowPageScroll: "auto"
				});
			}
			$(window).on('resize', function(){
				setWidth();
				toggleElementsVisibility();
				//funkcja jest wywoływana dopiero po określonym czasie jaki minął po ostatniej zmianie szerokości okna
				//zabezpieczenie wymagane jest aby operacje nie były wykonywane od nowa dla każdego piksela różnicy wielkości okna
				clearTimeout(resizeDelay);
				resizeDelay = setTimeout(recalculateSlider, 500);
			});
		}
		init();
	});
}
