app.service('sessionStorage',function(console){

	var enabled = false;

	function getItem(name){
		return enabled ? window.sessionStorage.getItem(name) : null;
	}
	function setItem(name,value){
		if(enabled){
			window.sessionStorage.setItem(name,value);
		}
	}
	function notEmpty(name){
		return (getItem(name)!==null && getItem(name)!=='');
	}
	function empty(name){
		return (getItem(name)===null || getItem(name)==='');
	}
	function removeItem(name){
		if(enabled){
			window.sessionStorage.removeItem(name);
		}
	}
	function clear(){
		if(enabled){
			window.sessionStorage.clear();
		}
	}
	try {
		enabled = window.sessionStorage ? true : false;
	} catch (e) {
		console.log('Wyłączony dostęp do sessionStorage');
	}

	return {
		getItem:getItem,
		setItem:setItem,
		removeItem:removeItem,
		empty:empty,
		clear:clear
	};
});
