/**
	*@module console
*/
app.service('console', function ($global) {
	/**
	* @param {array} list nowa tablica
	*/
	var list = [];
	const isAtr = document.cookie.indexOf('atrUser') > -1;
	var showLog = ((location.port.indexOf(8090) > -1 || location.port.indexOf(8080) > -1 || location.host.indexOf('katalog.qa') > -1 || location.host.indexOf('www.qa') > -1 || localStorage.getItem('showLog') == 'true' || isAtr) ? true : false);

	/**
	* @function log
	*  - metoda wpisująco daną wartość do konsoli
	*/
	function log() {
		try {
			if (showLog) {
				var result = (arguments[1]) ? arguments : arguments[0];
				if (!isAtr) {
					list.push(result)
				}
				window.console.info(result);
			}
		} catch (e) { }
	}

	/**
	* @function getList
	*  - metoda zwracająca liste logów
	* @return {array}
	*/
	function getList() {
		return list;
	}
	if (isAtr) {
		var oldConsoleLog = console.log;
		var oldConsoleInfo = console.info;
		console.info = function () {
			list.push({ info: arguments[1] ? [...arguments] : arguments[0] });
			oldConsoleInfo(arguments[1] ? arguments : arguments[0]);
		}
		console.log = function () {
			list.push({ log: arguments[1] ? [...arguments] : arguments[0] });
			oldConsoleLog(arguments[1] ? arguments : arguments[0]);
		}
		window.onerror = (a, b, c, d, e) => {
			list.push({
				error: {
					message: a,
					source: b,
					lineno: c,
					colno: d,
					error: e
				}
			});
		};
	}
	$global('getConsoleList', getList);

	return {
		log: log,
		list: getList
	};
});
