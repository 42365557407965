jQ = jQuery;
$.support = {
	opacity: true,
	cors: true
};
$.browser = {
	msie: ((navigator.appVersion.indexOf("MSIE") > -1) ? true : false),
	safari: ((navigator.userAgent.indexOf("Safari") > -1) ? true : false),
	mozilla: ((navigator.userAgent.indexOf('Firefox') > -1) ? true : false),
	opera: ((navigator.userAgent.toLowerCase().indexOf("op") > -1) ? true : false),
	version: ((navigator.appVersion.indexOf("MSIE 9") > -1) ? 9 : 10)
}
$.fn.live = function(event, callback) {
	$(this).on(event, callback);
}
timeoutOnToCard = function() {};

String.prototype.splice = function(idx, rem, str) {
	return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

if (!String.prototype.startsWith) {
	String.prototype.startsWith = function(searchString, position) {
		position = position || 0;
		return this.indexOf(searchString, position) === position;
	};
}

jQuery.ajaxSettings.traditional = true;

$.fn.andSelf = function() {
	return this.addBack.apply(this, arguments);
}
$.fn.serializeObject = function() {
	var o = {};
	this.find("[name]").each(function() {
		o[this.name] = this.value;
	});
	return o;
};
