function TestAB(cookie){
	this.nameCookie=(cookie!=null) ? cookie : 'testAB';
	this.logUrl='/visits-analytics.ltr',
	this.table=[];
	this.getTable=function(){
		this.table=[];
		var tmp=$.cookie(this.nameCookie);
		if(tmp!=null && tmp!=''){
			tmp=tmp.indexOf('::')>-1 ? tmp.split('::') : tmp.split('|');
			var that=this;
			$(tmp).each(function(i,n){
				var t=n.split(':');
				n0='_'+t[0];
				n1=t[1];
				that.table.push(n0);
				that.table[n0]=n1;
			});
		}
	};

	this.setVar=function(name,value){
		this.getTable();
		if(typeof this.table['_'+name] == "undefined")this.table.push('_'+name);
		this.table['_'+name]=value;

//		if(value==null)this.logVar({action:'delete',test:name});
//		else this.logVar({action:'set',test:name,value:value});

		this.synchronize();
	};
	this.getVar=function(name){
		if(!this.table.length)this.getTable();
		if(typeof this.table['_'+name] == "undefined")return null;
		else return this.table['_'+name];
	},
	this.delVar=function(name){
		this.setVar(name,null);
	},
	this.logVar=function(data){
		data.type='testAB';
		$.ajax({
			url:this.logUrl,
			type: 'POST',
			data:data,
			asyns:true,
			success:function(){}
		});
	},
	this.synchronize=function(){
		var cookie='';
		var that=this;
		$(this.table).each(function(i,n){
			var value=that.table[n];
			var key=n.substr(1);
			if(value!=null)cookie+=key+':'+value+'::';
		});
		if(cookie.length>0)cookie=cookie.substr(0, cookie.length-2);
		$.cookie(this.nameCookie,cookie,{ path: '/',expires: 365});
	};
};
var test=new TestAB();
