app.service('callback',function($global, console){
	var list=[];
	function add(name,task){
		list[name]=task;
	}
	function getList(){
		console.log(list);
	}
	function run(name,params){
		try{
			eval(name(params));
			console.log('CALLBACK run: '+name);
		}catch(e){
			if(typeof list[name] != "undefined"){
				list[name](params);
				console.log('CALLBACK run: '+name);
			}
		}
	}

	add('get503Page',function(){						//do weryfikacji
		$.ajax({
			url : '/upload_module/inne/kolejka-body.html',
			success : function(response) {
				$("body").html(response);
			}
		});
	});
	$global('addCallback',add);
	return {
		add:add,
		run:run,
		list:getList
	};
});