(function($) {
	/**
	 * Główna funkcja pluginu
	 *
	 * @param {object} event - Obiekt przechowujący informacje o wciśniętym klawiszu
	 */
	$.fn.selectMulti = function(setting) {
		return this.each(function() {


			var $container = $(this);
			var $optionList = $container.find('.select-multi-option-list');
			var $option = $container.find('.select-multi-option');
			var $selectedItem = $container.find('.select-multi-selected-item');
			var $allCheckbox = $container.find('input:checkbox');
			var $checkbox = $allCheckbox.filter('[value!="all"]');
			var $all = $allCheckbox.filter('[value="all"]');

			var hidden = true;

			function initEvents(){
				$container.on('click', function(){
					clickContainer();
				});
				refreshSelectedItem();

				$allCheckbox.on('click', function(event){
					clickOption(event)
				});

				$(document).on("click", abortList);
			}
			function refreshSelectedItem(){
				//console.log($all.is(':checked'));
				var $checked = $allCheckbox.filter(':checked');
				var length = $checked.length;

				if(length==1){
					$selectedItem.text($allCheckbox.filter('[value='+$checked.first().val()+']').parent().text());
				}else if(length>1 && length<5){
					$selectedItem.text('Wybrano '+length+' elementy');
				}else if(length>4){
					$selectedItem.text('Wybrano '+length+' elementów');
				}else{
					$selectedItem.text('Brak wybranych elementów');
				}

			}
			function clickOption(event){
				if($all && event.target){
					var $input = $(event.target);
					var value = $input.val();
					if(value=='all'){
						$checkbox.prop('checked',false);
					}
					if(value>0 && $input.is(':checked')){
						$all.prop('checked', false);
					}else if($allCheckbox.filter(':checked').length == 0){
						$all.prop('checked', true);
					}
				}
				refreshSelectedItem();
			}
			function clickContainer(){
				if(hidden){
					showList();
				};
			}
			function hideList(){
				hidden = true;
				$optionList.addClass('select-multi-list-hidden');
			}
			function showList(){
				hidden = false
				$optionList.removeClass('select-multi-list-hidden');
			}

			function abortList(event) {
				if( !$(event.target).closest('.select-multi').length) {
					hideList();
				}
			}
			initEvents();

		});
	}
})(jQuery);
