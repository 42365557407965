$.fn.rotator = function() {

	return this.each(function() {
		var $rotator = $(this);
		var $progressPointers;
		var bannersLength, delay, delayedNextOn, cooldownTimer;
		var currentPosition = 0;

		function setPosition(position) {
			if (position < 0) {
				position = bannersLength - 1;
			}
			if (position > bannersLength - 1) {
				position = 0;
			}
			if (position != currentPosition) {

				$rotator.addClass('shift-' + position);
				$rotator.removeClass('shift-' + currentPosition);

				currentPosition = position;
			}
			$( document ).trigger('lazyLoad:check');
		}
		function nextBanner() {
			setPosition(currentPosition + 1);
		}
		function previousBanner() {
			setPosition(currentPosition - 1);
		}
		function delayedNextBanner() {
			setInterval(function() {
				if (delayedNextOn) {
					nextBanner();
				}
			}, delay);
		}
		function cooldown(time) {
			delayedNextOn = false;
			if (cooldownTimer) {
				clearTimeout(cooldownTimer);
			}
			cooldownTimer = setTimeout(function() {
				delayedNextOn = true;
				cooldownTimer = false;
			}, time);
		}
		function setElements(){
			$progressPointers = $('.js-rotator-pointer', $rotator);
			bannersLength = $rotator.find('.js-banner-item').length;
			delay = $rotator.data('delay');
			delayedNextOn = bannersLength > 1;
		}
		function setEvents(){
			$progressPointers.on('click', function() {
				setPosition($(this).index());
				cooldown(3000);
			});
			delayedNextBanner();
		}
		function init(){
			setElements();
			setEvents();
		}

		init();
	});
};
