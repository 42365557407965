(function ( $ ) {
	$.fn.cityAutocomplete = function(zipCodeInput, cityNameInput) {
		
		var $zipCodeInput=$(zipCodeInput);
		var $cityNameInput=$(cityNameInput);
		var $cityList=$('<ul class="city-autocomplete-list list-empty"></ul>').insertAfter($cityNameInput);
		var scrollY = 0;

		var xhr;
		var cache=[];
	
		function changeZipCode(){
			clearOptionList();
			if(zipCodeIsCorrect()) {
				$cityNameInput.trigger('focus');
				//getList();
			}
		}
		function selectOption(element){
			$cityNameInput.val(element.text());
			if(element.data('zipcode')!==""){
				$zipCodeInput.val(element.data('zipcode'));
			}
			//$cityNameInput.trigger('keyup');
			hideOptionList();
		}
		function init() {

			$('body').on('click',function(event){
				var isAutocompleteField = $(event.target).data('city-autocomplete')==true;
				if(!isAutocompleteField){
					clearOptionList();
				}
			});
			$zipCodeInput.data('city-autocomplete',true).attr({
				spellcheck:"false",
				autocapitalize:"off",
				autocorrect:"off",
				autocomplete:"off"
			}).addClass('city-autocomplete-zipCode').on('keyup change', function(event){
				changeZipCode();
			}).on('focus', function(event){
				
			});
			$cityList.on('click', '.city-name-list-item', function(){
				selectOption($(this));
			});
			$cityNameInput.data('city-autocomplete',true).attr({
				spellcheck:"false",
				autocapitalize:"off",
				autocorrect:"off",
				autocomplete:"off"
			}).addClass('city-autocomplete-cityName').on('keydown focus change', function(event){
				
				var key = event.keyCode,
					$listItems = $cityList.find('li:not(.city-name-list-item-hidden)'),
					$selected = $listItems.filter('.selected'),
					$current = $({});

				if($selected.length && key === 13) {
					var regex = /(<([^>]+)>)/ig;
					$(this).val($selected[0].innerHTML.replace(regex, ""));
				}
				
				$listItems.removeClass('selected');

				if(key === 40) {
					var isLastElement = !$selected.nextAll().not('.city-name-list-item-hidden').length;

					!$selected.length || isLastElement ?
						$current = $listItems.first() :
						$current = $selected.nextAll().not('.city-name-list-item-hidden').first();

						var currentBottom = $current[0].getBoundingClientRect().bottom,
							diff = currentBottom - $cityList[0].getBoundingClientRect().bottom;

							if(isLastElement) {
								scrollY = 0;
							} else if(diff >= 0) {
								scrollY += diff;
							} 

							$cityList.scrollTop(scrollY);
				}
				
				if(key === 38) {
					var isFirstElement = !$selected.prevAll().not('.city-name-list-item-hidden').length;
					
					!$selected.length ||  isFirstElement ?
						$current = $listItems.last() :
						$current = $selected.prevAll().not('.city-name-list-item-hidden').first();

						var currentTop = $current[0].getBoundingClientRect().top,
							diff = currentTop - $cityList[0].getBoundingClientRect().top,
							listHeight = 0;

							$listItems.each(function() {
								listHeight += $(this).height();
							});
	
							if(isFirstElement) {
								scrollY = listHeight;
							} else if(diff < 0) {
								scrollY = $cityList.scrollTop() + diff;
							}
							$cityList.scrollTop(scrollY);
				}

				$current.addClass('selected');

				if($(this).val().length < 3 && !zipCodeIsCorrect()) {
					clearOptionList();
					return;
				}
				if(key>=37 && key<=40){
					return;
				}
				if(zipCodeIsCorrect() && typeof cache[$zipCodeInput.val()] !== "undefined" && $listItems.length) {
					filterList();
				}else {
					getList();
				}
			});
		}
		function zipCodeIsCorrect() {
			var isValid = false;
			var digits = $zipCodeInput.val().replace(/\D/g, '');
			if(digits.length === 5) {
				isValid = true;
				digits = digits.slice(0,2) + '-' + digits.slice(2);
				$zipCodeInput.val(digits)
			}
			return isValid;
		}
		function clearOptionList() {
			$cityList.html('');
		}
		function getList() {
			abortAjax();
			var cacheElement=''+$cityNameInput.val()+$zipCodeInput.val();
			if(typeof cache[cacheElement] !== "undefined"){
				buildList(cache[cacheElement]);
			}else{
				$cityNameInput.addClass('city-autocomplete-loading');
				xhr = $.ajax({
					method: 'GET',
					contentType: 'application/json',
					dataType: 'text',
					url: '/citiesWithZipCode.ltr',
					data: {
						q: $cityNameInput.val(),
						zipCode: $zipCodeInput.val(),
						type: 'city',
					},
					success: function(response){
						response=JSON.parse(response);
						cache[cacheElement]=response;
						buildList(response);
					},
					complete: function() {
						$cityNameInput.removeClass('city-autocomplete-loading');
					}
				});
			}
		}
		function buildList(list) {
			clearOptionList();
			$.each(list, function(i, val) {
				$cityList.append($('<li>', { class:'city-name-list-item', text: val.city, 'data-zipcode': val.zipCode }));
			});
			if(list.length){
				showOptionList();
			}
		}
		function abortAjax() {
			if(xhr && xhr.readystate != 4){
				xhr.abort();
			}
			$cityNameInput.removeClass('city-autocomplete-loading');
			hideOptionList();
		}
		function containsText(searchedText, searchedIn) {
			if(searchedIn.indexOf(searchedText) >= 0) {
				return true;
			} else {
				return false;
			}
		}
		function filterList() {
			$cityList.find('.city-name-list-item').each(function(i, val){
				var optionText = $(this).text().trim().toLowerCase();
				var cityNameInputValue = $cityNameInput.val().toLowerCase();
				if(!containsText(cityNameInputValue, optionText)) {
					$(this).addClass('city-name-list-item-hidden');
				} else {
					$(this).removeClass('city-name-list-item-hidden');
					var val = $cityNameInput.val();
					var text = $(this).text().trim();
					$(this).html(text.replace(new RegExp('(' + val + ')', 'gi'), '<b>$1</b>'));
				}
			});
			if($cityList.find('.city-name-list-item-hidden').length === $cityList.find('.city-name-list-item').length){
				hideOptionList();
			}
			else {
				showOptionList();
			}
		}
		function hideOptionList() {
			$cityList.addClass('list-empty');
		}
		function showOptionList() {
			$cityList.removeClass('list-empty');
		}
		init();
	}
	$.cityAutocomplete=$(window).cityAutocomplete;
}(jQuery));