function getUserId() {
	return $.cookie('userId');
}

function setUserId(id) {
	$.cookie('userId', id, {
		expires: 365,
		path: '/'
	});
}
var UA = (function() {
	var products = [];
	var promotions = [];
	var defaults = {
		product: {
			visible: false,
			sent: false,
			variant: ""
		},
		promotion: {
			name: '',
			position: 0,
			width: 0,
			height: 0
		}
	};
	var disabled = {
		productImpressions: false,
		productImpressionsAccesories: true,
	};
	var isAccesoriesDisabled = function(list) {
		return disabled.productImpressionsAccesories;
	};
	var unlockAccessories = function() {
		disabled.productImpressionsAccesories = false;
	};
	var generatePromotionDate = function(promotion, all) {
		var $element = $('.js-UA-promotion:visible').filter("[data-id=" + promotion.id + "]");
		var width = 0;
		var height = 0;
		var data;
		var $img;
		if ($element.length && $element.width()>0 && $element.height()>0 || all) {
			$img = $element.find('img');
			width = Math.round(promotion.width || parseFloat($img.width()));
			height = Math.round(promotion.height || parseFloat($img.height()));
			data = {
				id: promotion.type + '_' + promotion.id.split("-")[0],
				name: promotion.name,
				creative: (promotion.area) ? promotion.area.column + (promotion.area.name.length ? '_' + promotion.area.name : '') : 'productAdvertisingPlacement', //app.pageType(),
				position: width + 'x' + height
			};

			return data;
		} else {
			return false;
		}

	};
	var promotionClick = function(id) { // id promocji
		id += '';
		var promotion = getPromotion(id);
		if (promotion) {
			var result = generatePromotionDate(promotion);
			if (result !== false) {
				push({
					event: 'promotionClick',
					ecommerce: {
						promoClick: {
							promotions: [].concat(result)
						}
					}
				});
			}
		}
	};
	var productClick = function(id) { // id produktu lub element
		var product, $product, isNewProduct, productQuality, awsCampaignId;
		if (typeof id === "object") {
			id = getProductId(id);
		}
		product = getProduct(id);
		$product = $("#products [data-product-id=" + id + "]");
		productQuality = $product.data('product-quality');
		isNewProduct = !productQuality || productQuality === 'Nowy';
		awsCampaignId = product.campaign || null;
		if(product && product.plu){
			push({
				event: 'productClick',
				ecommerce: {
					click: {
						actionField: {
							'list': listRename(product.list)
						},
						products: [{
							name: product.name,
							id: product.plu,
							price: product.price,
							brand: product.brand,
							category: product.category,
							position: product.position,
							variant: product.variant,
							dimension45: isNewProduct ? 'Nowy' : 'Outlet',
							dimension46: isNewProduct ? 'Nowy' : productQuality,
							awsCampaignId: awsCampaignId
						}]
					}
				}
			});
		}
	};
	var removeFromCart = function(id) { // id produktu lub element
		if (typeof id === "object") {
			id = getProductId(id);
		}
		var product = getProduct(id);
		if (product && product.plu) {
			push({
				event: 'removeFromCart',
				ecommerce: {
					remove: {
						products: [{
							id: product.plu,
							name: product.name,
							price: product.price,
							brand: product.brand,
							category: product.category,
							quantity: 1,
							variant: product.variant
						}]
					}
				}
			});
		}
	};
	var productDetails = function(product) {
		if (product) {
			push({
				ecommerce: {
					detail: {
						actionField: {
							list: product.list
						},
						products: [{
							id: product.plu,
							name: product.name,
							price: product.price,
							brand: product.brand,
							category: product.category,
							variant: product.variant,
							dimension36: product.plu,
							dimension44: product.status
						}]
					}
				}
			});
		}
	};
	var addToCart = function(id, props) {
		var product = getProduct(id);
		if (product && product.plu) {
			push({
				event: 'addToCart',
				ecommerce: {
					currencyCode: 'PLN',
					add: {
						actionField: {
							'list': listRename(product.list)
						},
						products: [$.extend({}, {
							name: product.name,
							id: product.plu,
							price: product.price,
							brand: product.brand,
							category: product.category,
							quantity: 1,
							variant: product.variant
						}, props)]
					}
				}
			});
		} else {
			console.log('Brak produktu dla ID: ' + product);
		}
	};
	var cancelOrder = function(orderNumber) {
		var date = new Date(),
			year = date.getFullYear(),
			fullOrderNumber = orderNumber + "/" + year.toString();

		push({
			'ecommerce': {
				'refund': {
					'actionField': {
						'id': fullOrderNumber
					}
				}
			}
		});
	};
	var setProductImpressions = function() {
		var interval;
		var lastRange;
		var scrollDividers = [0, 20, 40, 60, 80, 100];
		var actualScrollDivider = false;

		function isReady(product) {
			return !product.sent && product.visible && product.list !== 'Koszyk';
		}

		function prepare() {
			var results = [];
			$.each(products, function(i, product) {
				var result = {};
				var list = product.list;

				if (!isAccesoriesDisabled(list) && isReady(product)) {
					results.push({
						id: product.plu,
						list: listRename(product.list),
						position: product.position,
						name: product.name,
						price: product.price,
						brand: product.brand,
						category: product.category,
						variant: product.variant,
						dimension44: product.status,
						dimension45: product.dimension45,
						dimension46: product.dimension46
					});
					product.sent = true;
				}
			});
			return results;
		}

		function getRange(num) {
			var range;

			$.each(scrollDividers, function(i, value) {
				if (num >= value) {
					range = value;
				}
			});
			if (range === 0) {
				return 'baseline';
			} else {
				return range;
			}
		}

		function getScrollDistance() {
			return parseInt(100 * ($(window).scrollTop() + $(window).height()) / $(document).height());
		}

		function send() {
			var range;
			var interactionRange;
			var eventNonInteraction = true;
			var data = prepare();
			if (data.length > 0) {
				setTimeout(function() { // timeout w oczekiwaniu na kolejne dane (ajax)
					push({
						'ecommerce': {
							'impressions': data
						}
					});

					range = getRange(getScrollDistance());

					if (range > 0) {
						interactionRange = range;
						eventNonInteraction = '';
					} else {
						interactionRange = 'baseline';
					}
					interaction('ScrollDistance', 'Scroll Depth', interactionRange, '', eventNonInteraction);
				}, 200);
			}
		}
		if (!disabled.productImpressions) {
			interval = setInterval(function() {
				send();
			}, productImpressionsTimeout); // zmienic na 3s jesli bedzie zgoda
		}
	};
	var productImpressionsTimeout=1000;

	var auth = function() {
		push({
			'userId': getUserId() || '',
			'event': 'authentication'
		});
	};
	var setProductClicks = function() {
		$(document).on('click', '.js-UA-product a', function() {
			var $a = $(this);
			var productHref = $a.closest('.js-UA-product').find('.productHref').val();
			var href = $a.attr('href');

			if (href && href.indexOf(productHref) != -1 || document.cookie.indexOf('atrUser')>-1) {
				productClick($a);
			}
		});
	};
	var setPromotionClicks = function() {
		$(document).on('click', '.js-UA-promotion a', function(e) {
			var id = $(this).closest('.js-UA-promotion').data('id');
			promotionClick(id);
		});
	};
	var userId = function() {
		var id = getUserId();
		if (id) {
			push({
				'userId': id,
			});
		}
	};
	var getProductId = function(el) {
		var id = $(el).closest('.js-UA-product').find('.productId').val();
		if (!id && app.pageType() === 'product-card') {
			id = $('#product-top').data('product') / 1;
		}
		if (id > 0) {
			return id;
		} else {
			console.log('UA: Nie znaleziono produktu');
		}
	};
	var addPromotion = function(obj) {
		if (isUniquePromotion(obj.id)) {
			promotions.push($.extend({}, defaults.promotion, obj));
		}
		return true;
	};
	var addProduct = function(obj) {
		if (isUniqueProduct(obj.id, obj.list)) {
			products.push($.extend({}, defaults.product, obj));
		}
		return true;
	};
	var setProductVisible = function(id, list) {
		if (id > 0 && list) {
			$.each(products, function(i, product) {
				if (product.id === id && product.list === list) {
					product.visible = true;
					return false;
				}
			});
		}
	};

	var setDisable = function(eventName) {
		disabled[eventName] = true;
	};
	var getProductsList = function(prop, value) {
		var table = [];
		$(products).each(function(i, n) {
			if (prop) {
				if (n[prop] === value) {
					table.push(n);
				}
			} else {
				table.push(n);
			}
		});
		return table;
	};

	var getPromotionsList = function(prop, value) {
		var table = [];
		$(promotions).each(function(i, n) {
			if (prop) {
				if (n[prop] === value) {
					table.push(n);
				}
			} else {
				table.push(n);
			}
		});
		return table;
	};

	function listRename(list) {
		var nodeMap = {
			'product-card': 'KP',
			'product': 'KP', //dla ole
			'leaf': 'Listing',
			'productsLis': 'Listing', //dla ole
			'acticle': 'Artykuły',
			'watchedProducts': 'Schowek',
			'comparison': 'Porównanie',
			'to-cart': 'K0'
		};
		var map = {
			'product-card': {
				//'Wasze Rekomendacje': 'KP - Wasze rekomedacje',
				'Karta produktu': 'KP - Produkt',
				//'Akcesoria': 'KP - Akcesoria',
				//'Zestawy': 'KP - Zestawy',
				//'Pasuje do': 'KP - Pasuje do',
				//'Rekomendowane': 'KP - Rekomendowane',
				//'Rekomendowane dla Ciebie': 'KP - Rekomendowane dla Ciebie',
				//'Belka' : 'KP - Belka',
				//'Belka - modal' : 'KP - Belka - Warstwa'
			},
			leaf: {
				'Listing': 'Listing',
				//'Wasze rekomendacje': 'Listing - Wasze rekomendacje'
			},
			mainPage: {
				'Ostatnio ogladane': 'SG - Ostatnio ogladane',
				'Rekomendowane dla ciebie': 'SG - Rekomendowane dla Ciebie'
			},
			accountLogin: {
				//'Ostatnio ogladane': 'Ostatnio Ogladane - Logowanie / Rejestracja'
			},
			precart: {
				'precart': 'K0',
				'Dosprzedaż akcesoriów': 'K0 - Dosprzedaż akcesoriów'
			},
			comparison: {
				'Porównanie': 'Porównanie'
			}
		};
		if (map.hasOwnProperty(app.pageType())) { //jeśli szczegolowe tłumaczenie node + place
			var m = map[app.pageType()];

			if (m[list]) {
				return m[list];
			}
		}
		if (nodeMap[app.pageType()]) { // tłumaczenie node + przeniesiony place
			return nodeMap[app.pageType()] + ' - ' + list;
		}
		return app.pageType() + ' - ' + list; //domyśna node +przeniesione place
	}

	function isUniquePromotion(id) { //util
		var result = true;
		$(promotions).each(function(i, promotion) {
			if (promotion.id == id) {
				result = false;
				return false;
			}
		});
		return result;
	}

	function isUniqueProduct(id, list) { //util
		var product;
		for (var key in products) {
			product = products[key];
			if (product.id === id && product.list === list) {
				return false;
			}
		}
		return true;
	}
	var pageStructure = function(data) {
		var isValid = data && data.category;
		if (isValid) {
			push({
				kategoria: data.category,
				grupa: data.group,
				nazwa: data.name
			});
		}
	}
	function getPromotion(id) { //util
		id += "";
		var promotion;
		for (var key in promotions) {
			promotion = promotions[key];

			if (promotion.id == id) {
				return promotion;
			}
		}
		console.log('Nie znaleziono produktu');
	}

	function getProduct(id) { //util
		id += '';
		var result = products.filter(function(product){
			return product.id == id && product.plu && product.name;
		});
		if(result.length){
			return result[0];
		}else{
			console.log('Nie znaleziono produktu: '+id);
			return {};
		}
	}
	var allPush = [];
	var getPush = function() {
		return $.extend(true, [], allPush);
	};

	function sendFallbackData(callbackSuccess) {
		if(online === true){

		}else if(online === false){
			callbackSuccess();
		}else{
			setTimeout(function(){
				sendFallbackData(callbackSuccess);
			},500);
		}
	}
	var tmpPush = [];
	var push = function(params) {
		if(online === true || online === false){
			try {
				if (online === false && (params.event === 'virtualPageview' || params.event === 'orderComplete' || params.userIncognito || params.userAdblock)) {
					app.setFallbackData( params );
				} else {
						window.dataLayer = window.dataLayer ? window.dataLayer : [];
					window.dataLayer.push(params);
				}
				allPush.push(params);
			} catch (error) {
				console.log('UA PUSH ERROR: ' + error);
			}
		}else{
			tmpPush.push(params);
		}
	};
	var searchInteraction = function (category = '', action = '', label = '', value = '') {
		push({
			'event': 'searchVisitorInteraction',
			'eventCategory': category,
			'eventAction': action,
			'eventLabel': label,
			'eventValue': value
		});
	};
	var pageInteraction = function() {
		var pages = {
			"product-detail": "KP",
			"product-card": 'KP',
			"leaf": 'Listing',
		};
		var params = [];
		params.push(pages[app.pageType()]);
		$(arguments).each(function(i, n) {
			params.push(n);
		});
		interaction(params);
	};
	var interaction = function() {
		var params = arguments[0];
		if (arguments[1]) {
			params = arguments;
		}
		if (params[0]) {
			push({
				'event': 'visitorInteraction',
				'eventCategory': params[0],
				'eventAction': params[1] || '',
				'eventLabel': params[2] || '',
				'eventValue': params[3] || '',
				'eventNonInteraction': params[4] || ''
			});
		}
	};
	var lastPageUrl = '';
	var pageview = function(url) {
		var data = {
			'event': 'virtualPageview',
			'virtualPageURL' : undefined
		};
		if (url) {
			data.virtualPageURL = url;
		} else if (pageUrl) {
			data.virtualPageURL = pageUrl;
		}
		if(lastPageUrl !== data.virtualPageURL) {  //jesli adres sie nie zmienil, nie wysylamy
			push(data);
		}
		lastPageUrl = data.virtualPageURL;
	};
	var setVar = function() {
		push({
			'event': 'setCustomDimension',
			'setVar': arguments[0]
		});
	};
	var pageUrl = null;
	var setPageUrl = function(url) {
		var params = $.parseQuery();
		if(params.link && url.indexOf('link=') === -1){
			url += '&link=' + params.link;
		}
		if(params.from && url.indexOf('from=') === -1){
			url += '&from=' + params.from;
		}
		pageUrl = url;
	};
	var removePageUrl = function() {
		pageUrl = '';
	};

	function userLogged() {
		var cookie = $.cookie('userLogged');
		if (cookie) {
			setUserId(cookie); //globalnie
			auth();
			$.cookie('userLogged', '', {
				expires: -1
			});
		}
	}
	var userEmail = function() {
		var cookieValue = $.cookie("loggedUserEmail");
		if (cookieValue) {
			push({
				'md5email': cookieValue.indexOf(":")>-1?cookieValue.split(":")[1]:cookieValue
			});
		}
	};

	function translatePageType(){
		var pages={
			'product-card':'product',
			'leaf':'productsList',
			'main-page':'mainPage',
			//'node' : 'node',
		};
		var pageType=app.pageType();
		if(pages[pageType]){
			return pages[pageType];
		}else{
			return pageType;
		}
	}
	function uniquePush(data, id) {
		if(window.sessionStorage.getItem("orderFinalize") !== id) {
			window.sessionStorage.setItem('orderFinalize', id);
			push(data);
		}
	}
	var refreshBasket = function(products){
		var result=[];
		$(products).each(function(i,product){
			result.push({
				'name': product.name,
				'id': product.plu,
				'price': product.price,
				//'brand': 'Google',
				'category': product.category,
				//'variant': 'Gray',
				'quantity': product.quantity,
				'dimension36': product.id
			});
		});
		push({
			'ecommerce': {
				'actionField': {
					'list': 'maly koszyk',
					'action': 'detail'
				},
				'detail': {
					'products': result
				}
			}
		});
	};
	var orderFinalize = function(data) {
		var isValid = data && data.ecommerce;

		if (isValid) {
			var id = data.ecommerce.purchase.actionField.id;
			uniquePush(data, id);
		}
		}
	var pageCategory = function() {
		push({
			'pageCategory': translatePageType(),
			'front': 'desktop',
			'url': document.location.href,
			'cannonical-url': document.location.protocol + '//' + document.location.host + document.location.pathname
		});
	};
	var userAdblock = function () {
		push({
			userAdblock: online ? 'off' : 'on'
		});
	}

	var userIncognito = function() {
		const isIncognito = new Promise(function(resolve, reject) {
			if (/Chrome/.test(navigator.userAgent)) {  //dotyczy wszystkich przegladarek na silniku Chromium - takze przegladarki Edge
				const limit = performance.memory.jsHeapSizeLimit || 1073741824;   //limit pamięci dla chrome - wynik poniżej tej wartości wskazuje na tryb incognito; dla wersji <77 ustalona stala wartosc
				navigator.webkitTemporaryStorage.queryUsageAndQuota(function (_, memorySize) {
					resolve(memorySize < limit);
				});
			} else if (/Firefox/.test(navigator.userAgent)) {
				const db = indexedDB.open('test'); //wartosc defaultowa dla indexedDB to 'test'
				db.onerror = function () {  //indexedDB nie jest dostępny dla trybu prywatnego - w tym przypadku zadziała metoda onerror()
					resolve(true);
				};
				db.onsuccess = function () {
					resolve(false);
				}
			} else if (/Safari/.test(navigator.userAgent)) {
				const storage = window.sessionStorage;
				try {
					storage.setItem("testKey", "test");
					storage.removeItem("testKey");
					resolve(false);
				} catch (error) {   //w trybie inPrivate Safari blokuje zapis do sessionStorage
					if (error.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
						resolve(true);
					}
				}
			} else { //dla pozostalych przegladarek nic nie wysylamy
				reject();
			}
		});

		isIncognito.then(function(isIncognito) {
			push({
				userIncognito: isIncognito ? 'on' : 'off'
			});
		})
	}

	var error = function(page) {
		var url = "/blad-decoration-error" + document.location.pathname + document.location.search;
		if (page) {
			url += '/bloczek=' + page;
		}
		pageview(url);
	};
	var doubleClick = function(data, event){
		if(data && event){
			push({
				event : event,
				_dcEuroEvtHelper : data
			});
		}
	};
	var isDisabled = function() {
		var $html = $('html');
		return ($html.hasClass('operator-reserve-bar') || $html.hasClass('operator-logged') || $html.hasClass('app-katalog'));
	};
	var setConfig = function(params){
		if(params.productImpressionsTimeout){
			productImpressionsTimeout=params.productImpressionsTimeout;
		}
	}
	var init = function() {
		userLogged();
		userId();
		userEmail();
		setProductClicks();
		if ((typeof www !=="undefined" && www.pageType != 'mainPage') || !isDisabled()) {
			setProductImpressions();
		}
		setPromotionClicks();
	};
	var online=null;
	if(window.sessionStorage.getItem('UA') ==='online'){
		online=true;
	}else if(window.sessionStorage.getItem('UA') ==='offline'){
		online=false;
	}else{
		$.ajax({
			url:'https://www.google-analytics.com/collect',
			success:function(h){
				online=true;
			},
			error: function() {
				online=false;
			},
			complete: function() {
				window.sessionStorage.setItem('UA', online ? 'online' : 'offline');
				if(tmpPush.length){
					tmpPush.forEach((item, i) => {
						push(item);
					});
					tmpPush = [];
				}
				//wysylamy do analityki tylko raz na sesje
				userAdblock();
				userIncognito();
			}
		});
	}

	return {
		init: init,
		auth: auth,
		push: push,
		error: error,
		getPush: getPush,
		pageCategory: pageCategory,
		pageStructure: pageStructure,
		addPromotion: addPromotion,
		addProduct: addProduct,
		setProductVisible: setProductVisible,
		setDisable: setDisable,
		refreshBasket : refreshBasket,
		sendFallbackData: sendFallbackData,

		removeFromCart: removeFromCart,
		addToCart: addToCart,
		interaction: interaction,
		productClick: productClick,
		productDetails: productDetails,
		pageview: pageview,
		doubleClick: doubleClick,

		getPromotionsList: getPromotionsList,
		setVar: setVar,
		setPageUrl: setPageUrl,
		removePageUrl: removePageUrl,
		cancelOrder: cancelOrder,
		orderFinalize: orderFinalize,
		setConfig: setConfig,

		getProductsList: getProductsList,
		setProductImpressions: setProductImpressions, // dla strony glownej,
		unlockAccessories: unlockAccessories,
		pageInteraction: pageInteraction,
		searchInteraction
	};
})();
