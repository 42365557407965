app.service('lang',function(){ 
	
	var list={};
	
	//list["notifyCookie.button"]="Nie pokazuj";
	
	function getList(key){
		key=(key)?key.toString() : '';
		if(list[key]){
			return list[key];
		}else if(key.length>0){
			return 'Brak komunikatu: '+key;
		}else{
			return 'Brak klucza';
		}
	}
	return getList;
});