
jQuery.extend(jQuery.validator.messages, {
	required: "To pole jest wymagane.",
	remote: "Proszę o wypełnienie tego pola.",
	email: "Proszę o podanie prawidłowego adresu email.",
	url: "Proszę o podanie prawidłowego URL.",
	date: "Proszę o podanie prawidłowej daty.",
	dateISO: "Proszę o podanie prawidłowej daty (ISO).",
	number: "Proszę o podanie prawidłowej liczby.",
	digits: "Proszę o podanie samych cyfr.",
	creditcard: "Proszę o podanie prawidłowej karty kredytowej.",
	equalTo: "Proszę o podanie tej samej wartości ponownie.",
	accept: "Proszę o podanie wartości z prawidłowym rozszerzeniem.",
	maxlength: jQuery.validator.format("Proszę o podanie nie więcej niż {0} znaków."),
	minlength: jQuery.validator.format("Proszę o podanie przynajmniej {0} znaków."),
	rangelength: jQuery.validator.format("Proszę o podanie wartości o długości od {0} do {1} znaków."),
	range: jQuery.validator.format("Proszę o podanie wartości z przedziału od {0} do {1}."),
	max: jQuery.validator.format("Proszę o podanie wartości mniejszej bądź równej {0}."),
	min: jQuery.validator.format("Proszę o podanie wartości większej bądź równej {0}.")
});

function nipValidator(nip){
	var verificator_nip = new Array(6,5,7,2,3,4,5,6,7);
	var n = 0;var ile;
	for (var i=0; i<9; i++) {
		ile=nip.charAt(i)/1 * verificator_nip[i]/1;
		n += ile;
	}
	// walidacja odrzuca nip z samymi zerami
	if (n === 0) {
		return false;
	}
	n %= 11;
	if (n != nip.charAt(9)) {
		return false;
	}else{
		return true;
	}
}


function dowodOsobisty(numer) {
	numer=numer+'';
	numer=numer.match(/[A-Za-z0-9]/gi).join('').toUpperCase();

	if (numer == '' || numer.length != 9){
		return false;
	}

	var letterValues = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A',
			'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
			'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z' ];
	function getLetterValue(letter) {
		for (j = 0; j < letterValues.length; j++)
			if (letter == letterValues[j])
				return j;
		return -1;
	}

	// Check seria
	for (i = 0; i < 3; i++)
		if (getLetterValue(numer[i]) < 10)
			return false;
	// Check number
	for (i = 3; i < 9; i++)
		if (getLetterValue(numer[i]) < 0 || getLetterValue(numer[i]) > 9)
			return false;

	// sprawdz cyfre controlna
	sum = 7 * getLetterValue(numer[0]) + 3 * getLetterValue(numer[1]) + 1
			* getLetterValue(numer[2]) + 7 * getLetterValue(numer[4]) + 3
			* getLetterValue(numer[5]) + 1 * getLetterValue(numer[6]) + 7
			* getLetterValue(numer[7]) + 3 * getLetterValue(numer[8]);
	sum %= 10;
	if (sum != getLetterValue(numer[3])){
		return false;
	}
	return true;
}

function houseNumber(numer, max){
	if (numer == null || numer.length > max)return false;
	if(/\d/.test(numer) && !/;/.test(numer) && /^[^\-].*/.test(numer))return true;
	return false;
}

jQuery.validator.addMethod("houseNumber", function(value, element) {
	var max=$(element).attr('maxlength') || 10;
	return this.optional(element) || houseNumber(value, max/1);
	}, "Podaj poprawny numer domu"
);

jQuery.validator.addMethod("flat", function(value, element) {
	var max=$(element).attr('maxlength') || 10;
	return this.optional(element) || houseNumber(value, max/1);
}, "Format mieszkania jest niepoprawny");

jQuery.validator.addMethod("dowodOsobisty", function(value, element) {
	return this.optional(element) || dowodOsobisty(value);
	}, "Podaj poprawny numer dowodu osobistego"
);

jQuery.validator.addMethod("money", function(value, element) {
	return this.optional(element) || (/^([1-9][0-9]*|0)((\.|\,)[0-9]{2})?$/.test(value) && parseInt(value) <= 999999);
	}, "Podaj poprawną kwotę"
);

jQuery.validator.addMethod("firstname", function(value, element) {
	//^[-\s]+$ sprawdza czy string jest złożony TYLKO ze znaku/znaków "-"
	return this.optional(element) || (/^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ']{1}[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ\- ']{1,39}$/.test(value) && !/.[\s]{2,}/.test(value) && !/.[-]{2,}/.test(value));
	}, "Imię zawiera niepoprawne znaki"
);

jQuery.validator.addMethod("surname", function(value, element) {
	return this.optional(element) || (/^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ']{1}[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ\- ']{1,39}$/.test(value) && !/.[\s]{2,}/.test(value) && !/.[-]{2,}/.test(value));
	}, "Nazwisko zawiera niepoprawne znaki"
);

jQuery.validator.addMethod("strongPassword", function () {
	return passwordInvalid.check();
}, "");

jQuery.validator.addMethod("city", function(value, element) {
	return this.optional(element) || /^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ\- .]{2,40}$/.test(value);
	}, "Podaj poprawną nazwę miejscowości"
);

jQuery.validator.addMethod("nip", function(value, element) {
	var status=false;
	if(jQuery(element).hasClass('ignore'))status=true;
	else{
		var nip = value.replace(/[\ _\-]/gi, '');
		if(/\d{3}-\d{2}-\d{2}-\d{3}/.test(value) || /\d{3}-\d{3}-\d{2}-\d{2}/.test(value) || /\d{10}/.test(value)){
			status=true;
			if (nip.length != 10)  { status=false; }
			else {
				status=nipValidator(nip);
				if (!jQuery('html.app-katalog').length && status==true && !/\d{3}-\d{3}-\d{2}-\d{2}/.test(value)){
					jQuery(element).val(nip.substr(0,3)+'-'+nip.substr(3,2)+'-'+nip.substr(5,2)+'-'+nip.substr(7,3));
				}
			}
		}else if (nip == '' && jQuery(element).hasClass('individual'))  { status=true; }
		else{
			status=false;
		}
	}
	return status;
	}, "Podaj poprawny NIP  w formacie xxx-xx-xx-xxx"
);

function phone(value){
	var number = value + '';
	if (number.match(/\d/g) && number.match(/\d/g).length === 9 && /^[\d- ]*$/.test(number)){
		return true;
	}
	return false;
}

jQuery.validator.addMethod("trim", function(value, element, params) {
	if( typeof params === 'object' ) {
		return $.trim(value).length >= params.minlength ? true : false;
	} else {
		return $.trim(value) ? true : false;
	}
}, "Pole nie może być wypełnione spacjami");

jQuery.validator.addMethod("companyName", function (value, element) {
	function companyName(value) { 
		var result = value.split('').filter(function (y) {
			let char = y.charCodeAt();
			return char > 20 && char < 383;
		});
		return result.length == value.length;
	}
	return this.optional(element) || companyName(value);
}, "Nazwa firmy zawiera niepoprawne znaki");

jQuery.validator.addMethod("phone", function(value, element) {
	return this.optional(element) || phone(value);
}, "Podaj poprawny numer telefonu (9 cyfr)");

jQuery.validator.addMethod("zipCode", function(value, element) {
	return this.optional(element) || /\d{2}-\d{3}/.test(value) || (typeof emptyOrProperValidation != "undefined" && emptyOrProperValidation=='true') ;
}, "Podaj poprawny kod pocztowy w formacie xx-xxx");

jQuery.validator.addMethod("euroCard", function(value, element) {
	return this.optional(element) || /^[A-Za-z0-9]{16}$/.test(value) || /^[0-9]{8}$/.test(value);

}, "Niewłaściwy nr karty. Poprawny numer to np: Karta Klienta Euro - 0123456789012345 lub Karta Euro Raty - 01234567");

jQuery.validator.addMethod("pesel", function(value, element) {
	var status=true;
	var pesel = value.replace(/[\ \-]/gi, '');
	if (pesel.length != 11) { status=false; }
	else {
		var steps = new Array(1, 3, 7, 9, 1, 3, 7, 9, 1, 3);
		var sum_nb = 0;
		for (var x = 0; x < 10; x++) { sum_nb += steps[x] * pesel.charAt(x);}
		sum_m = 10 - sum_nb % 10;
		if (sum_m == 10) { sum_c = 0; }
		else { sum_c = sum_m;}
		if (sum_c != pesel.charAt(10)) {	status=false;}
	}
	return this.optional(element) || status;
}, "Podaj poprawny numer Pesel");

jQuery.validator.addMethod("email", function(value, element) {
	if (value==''){
		return true;
	}else {
		return app.getParam('emailReg').test(value);
	}
}, "Podaj poprawny adres e-mail");

jQuery.validator.addMethod(
	"datePL",
	function(value, element) {
		var check = false;
		var re = /^\d{1,2}-\d{1,2}-\d{4}$/;
		if( re.test(value)){
			var adata = value.split('-');
			var gg = parseInt(adata[0],10);
			var mm = parseInt(adata[1],10);
			var aaaa = parseInt(adata[2],10);
			var xdata = new Date(aaaa,mm-1,gg);
			if ( ( xdata.getFullYear() == aaaa ) && ( xdata.getMonth () == mm - 1 ) && ( xdata.getDate() == gg ) )
				check = true;
			else
				check = false;
		} else
			check = false;
		//if($(element).hasClass('ignore')){check=true;}
		return this.optional(element) || check;
	},
	"Format daty jest niepoprawny"
);

jQuery.validator.addMethod("ulica", function(value, element) {
	var status=false;
	var testReg1 = new RegExp("^[0-9a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ\\- .']{2,60}$");	//dozwolone znaki
	var testReg2 = new RegExp("[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ]+");					//min jedna litera
	if (testReg1.test(value) && testReg2.test(value)){
		status=true;
	}
	var s=$(element).parent().parent().find('.err');
	if(s.length)s.remove();
	return this.optional(element) || status;
}, "Ulica zawiera niepoprawne znaki");

jQuery.validator.addMethod("twoWords", function(value, element) {
	var status=false;
	var testReg=/[a-z]+\s[a-z]+/i;
	if(testReg.test(value)){
		status=true;
	}
	var s=$(element).parent().parent().find('.err');
	if(s.length)s.remove();
	return this.optional(element) || status;
}, "Podaj conajmniej dwa słowa");

$.validator.addMethod("dataUrodzin", function (value, element) {
	var today=new Date();
	var data=value.split('-');
	var DOB = new Date(data[2]/1,data[1]/1,data[0]/1,0,0,0);
	var max = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
	var rgexp = /(^(((0[1-9]|[12][0-8]|19)[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
	if(rgexp.test(value) && DOB < max) {
		return true;
	}
	return false;
 }, "Format daty urodzin jest niepoprawny");

$.validator.addMethod("agreementDateOfBirth", function (value) {
	if (value.length == 0){
		return true;
	}
	var today=new Date();
	var data=value.split('-');
	var selectedDateOfBirth = new Date(data[0]/1,data[1]/1-1,data[2]/1,0,0,0);
	var minAgreementDateOfBirth = new Date(today.getFullYear()-16, today.getMonth(), today.getDate(), 0, 0, 0);
	if(selectedDateOfBirth <= minAgreementDateOfBirth) {
		return true;
	}
	return false;
}, "Proszę o podanie wartości większej bądź równej {0}");

$.validator.addMethod("dateMax", function (value, element,param) {
	var t=value.split('-');
	var y=param.split('-');
	var status=(new Date(y[1]+'/'+y[0]+'/'+y[2]) >= new Date(t[1]+'/'+t[0]+'/'+t[2]));
	return this.optional(element) || status;
}, "Wybrana data jest niewłaściwa, maksymalna data to {0}");

$.validator.addMethod("dateMin", function (value, element,param) {
	var t=value.split('-');
	var y=param.split('-');
	var status=(new Date(y[1]+'/'+y[0]+'/'+y[2]) <= new Date(t[1]+'/'+t[0]+'/'+t[2]));
	return this.optional(element) || status;
}, "Wybrana data jest niewłaściwa, minimalna data to {0}");

$.validator.addMethod("dateLowestRange", function (value, element) {
	var t=$(element).val().split('-');
	var status=(new Date('01/01/1900') <= new Date(t[1]+'/'+t[0]+'/'+t[2]));
	return this.optional(element) || status;
}, "Data nie może być wcześniejsza niż 01-01-1900");

$.validator.addMethod("isSame", function (value, element,param) {
	var status= (value == param);
	return this.optional(element) || status;
}, "Niepoprawne dane. Dane na formularzu akceptacyjnym muszą być zgodne z umową kredytową.");

$.fn.extend({
	ignore: function(value) {
		var klasa='ignore';
		if(this.length){
			this.each(function(){
				var labelError=$('label.error[for="'+$(this).attr('id')+'"], span.error[htmlfor="'+$(this).attr('id')+'"], #'+$(this).attr('id')+'-error');
				if(value){
					$(this).addClass(klasa).removeClass('error').closest('.validate-target').removeClass('validate-error');
					labelError.remove();
				}else{
					$(this).removeClass(klasa);
				}
			});
		}
		return this;
	}
});

$.validator.addMethod("dataDDMMRRRR", function (value, element) {
	var rgexp = /(^(((0[1-9]|1[0-9]|2[0-8])[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
	if(rgexp.test(value)) {
		return true;
	}
	return false;
}, "Nieprawidłowy format daty. Prawidłowy to DD-MM-RRRR");

$.validator.addMethod(
	"orderNumberEuro",
	function (value, element) {
		var regExp = /(^\d{7,8}\/\d{4}$)|(^cc\d{4}\/\d{6})/i;
		return regExp.test(value);
	},
	function(params, element) {
		var value = element.value;
		var regExp = /^cc/i;
		var msg = "Nieprawidłowy format zamówienia. Prawidłowy to ";

		return msg +=  regExp.test(value) ?
		'CCrrrr/xxxxxx' :
		'xxxxxxx/rrrr lub xxxxxxxx/rrrr';
	}
);

$.validator.addMethod(
	"orderNumberOle",
	function (value, element) {
		var regExp = /(^(cca|cco)\d{4}\/\d{6,7})|(^\d{7,8}\/\d{4}$)/i;
		var regExpAla = /(^(ala)(\d{4}\/\d{6,7})$)|(^(ala)(\d{6,7}\/\d{4})$)/i;
		return regExp.test(value) || regExpAla.test(value);
	},
	function(params, element) {
		var value = element.value;
		var messages = {
			'cc': 'CCArrrr/xxxxxx lub CCOrrrr/xxxxxx',
			'al': 'ALAxxxxxxx/rrrr lub ALArrrr/xxxxxxx',
			'default': 'xxxxxxx/rrrr lub xxxxxxxx/rrrr'
		};
		var result = value.match(/^(al|cc)/i);
		var msg = 'Nieprawidłowy format zamówienia. Prawidłowy to ';

		return msg += result ?
		messages[ result[0].toLowerCase() ] :
		messages[ 'default' ];
	}
);

/*
	Walidacja rozmiaru plików

	param = maksymalny rozmiar
	element = walidowany element (<input>)
	value = wartość walidowanego pola (file name)
 */
$.validator.addMethod('filesize', function(value, element, param) {

	var size = 0;

	$.each($('input[name='+element.name+']'), function( key, input ) {
		var files = $(input)[0].files;
		var fl = files.length;
		var i = 0;

		while (i < fl) {
			var file = files[i];
			size += file.size;
			i++;
		}
	});

	return this.optional(element) || (size <= param)
}, "Przekroczono dozwolony rozmiar plików.");

/*
 Walidacja rozszerzenia pliku

 param = maksymalny rozmiar
 element = walidowany element (<input>)
 value = wartość walidowanego pola (file name)
 */
$.validator.addMethod( "extension", function( value, element, param ) {
	param = typeof param === "string" ? param.replace( /,/g, "|" ) : "png|jpe?g|gif";

	var isOk = true;

	$.each($('input[name='+element.name+']'), function( key, input ) {
		var files = $(input)[0].files;
		var fl = files.length;
		var i = 0;

		while ((i < fl) && isOk) {
			var file = files[i];
			isOk = isOk && file.name.match(new RegExp("\\.(" + param + ")$", "i"));
			i++;
		}

	});

	return this.optional( element ) || isOk;
}, "Podano zły format pliku" );

$.validator.setDefaults({
	ignore: ".ignore",
	errorElement: "span",
	errorPlacement: function(error, element){
		errorPlacementValidation(error, element);
	},
	success: function(label){
		successValidation(label);
	}
});

function errorPlacementValidation(error, element) {
	if(!element.hasClass('ignore')){
		var elementParent = element.parent();
		if(element.closest(".terms-checkbox").length) {
			elementParent = element.parent().parent().next();
		}
		if(element.parent().hasClass("checkbox-css") || element.parent().hasClass("radio-css")) {
			elementParent = element.parent().parent();
		}
		if(element.parent().next().hasClass('infoView')){
			elementParent = element.parent().next();
		}
		error.insertAfter(elementParent);

		error.addClass('errorText');
		if(error.text()!=''){
			element.parents('.errorBox').addClass('statusError').removeClass('statusOk');
		}
		$('.errorBox').each(function(){
			var span=$(this).find('span.error, label.error');
			span.filter(':empty').remove();
			if(span.length>1){
			 	span.not(':first').remove();
			}
		});
	}
}

function successValidation(label,eee) {
	var flat=label.parent().find('input, textarea, select').attr('id');
	if(typeof flat != 'undefined'){
		label.addClass("valid").addClass('checked').removeClass('error');
		if(label.parents('.errorBox').hasClass('statusError')){
			label.parents('.errorBox').addClass('statusOk').removeClass('statusError');
		}
		label.remove();
	}
}
