/**
 * modul opracji na ciasteczkach
 * @module cookie
 */
app.service('cookie',function(console) {
	/**
 	* @function
 	* ustawia ciasteczko
	* @param {text} key nazwa ciasteczka
 	* @param {number} value wartość ciasteczka
 	* @param {text} options dodatkowe opcje
	*/
	function set(key,value,options) {
		$.cookie(key,value,options);
		console.log('COOKIE set: '+key);
	}
	/**
 	* @function get
 	* odczyt ciasteczka
	* @param {text} key nazwa ciasteczka
	* @return {string}
	*/
	function get(key) {
		return $.cookie(key);
	}
	function split(key){
		var c =  get(key);
		if(c){
			if(c.indexOf("::")>-1){
				return c.split("::");
			}else {
				return c.split("|");
			}
		}else{
			return [];
		}
	}
	/**
	  @function isEmpty
	* sprawdza czy ciasteczko jest puste
	* @param {text} key nazwa ciasteczka
	*/
	function isEmpty(key){
		return (get(key)===undefined || get(key)==='');
	}
	/**
	  @function remove
	* usuwa ciasteczko
	* @param {text} key nazwa ciasteczka
	*/
	function remove(key,path){
		if(path){
			$.removeCookie(key, {path:path});
		}else{
			$.removeCookie(key);
		}
	}
	function removeGlobal(key){
		remove(key, '/');
	}
	return {
		set:set,
		get:get,
		isEmpty:isEmpty,
		remove:remove,
		split: split,
		removeGlobal: removeGlobal
	};
});
