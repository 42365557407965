/**
* @description warstwa pokazująca html, alerty, potykacze
* @module modal
*/
app.service('modal',function($global, console, broker, html){
	var alertModal=false;
	var alertCallback=false;
	var messageModal=false;
	var messages=[];
	var modalsCallbackClose=[];
	/**
	* @function open
	* @description wyświetla warstwę
	* @param {text} id identyfikator warstwy
	*/
	function open(id, params){
		if(isExist(id)){

			var defaults={
				hashTracking: false
			};
			var $modal = $('[data-remodal-id=' + id + ']').filter('.remodal');
			if($modal.length){ // przypadek gdy w kodzie na nowo pojawia sie ten sam modal, to usuwamy ten wstepnie wygenerowany, a otwieramy juz otwarty; usuwanie tylko dla przypadku istnienia modali z klasą .remodal (inaczej usuna sie wszystkie)
				$modal.filter(':not(.remodal)').remove();
			}
			console.log('otwieramy warstwę: ' + id);
			var modal = $('[data-remodal-id='+id+']').remodal($.extend(true,defaults,params));
			modal.open();
			if(params && params.callbackClose){
				modalsCallbackClose[id] = function(){
					params.callbackClose();
				}
			}
			return modal;
		}else{
			try{
				console.log('NIE ISTNIEJE MODAL: '+id);
			}catch(e){}
		}
	}
	/**
	* @function alert
	* @description wyświetla warstę z przekazanym tekstem i guzukiem OK
	* @param {text} text tekst do pokazania
	*/
	function alert(text, callback){
		if(typeof(text)==='object'){
			$result=$('<ul/>');
			text.forEach(function(a,i){
				$result.append($('<li/>').text(a));
			})
			$('#alert p').html($result);
		}else{
			$('#alert p').html(text);
		}
		alertCallback=callback || false;
		$(window).trigger('loadingStop');
		alertModal.open();
	}
	function confirm(message, yes, no){
		if(typeof(message)==='object' && message.length>1){
			var result=[];
			message.forEach(function(a,i){
				result.push('<li>'+a+'</li>');
			})
			message='<ul>'+result.join("")+'</ul>';
		}else{
			message='<p>'+message+'</p>';
		}
		generate({
			content: message,
			id: 'confirm',
			callbackOpen:function(){},
			buttons: [{
				text:'Tak',
				cssClass: 'btn-primary',
				click:function(){
					close();
					if(yes){
						yes();
					}
				}
			},{
				text:'Nie',
				cssClass: 'btn-primary',
				click:function(){
					close();
					if(no){
						no();
					}
				}
			}]
		});
		$(window).trigger('loadingStop');
	}
	function close(){
		var $modal = $('.remodal-is-opened .remodal');		// jeśli warstwa ma klasę ...-opening to oznacza że ma bład z uruchomieniem modala. Problemem może być css.
		if ($modal.length){
			$modal.remodal().close();
		}
		alertCallback = false;
		confirmYesCallback=false;
		confirmNoCallback=false;
	}
	function callbackClose($modal, callback){
		$modal['$modal'].on('click', '.remodal-close', function(){
			callback();
		});
	}
	/**
	 * @function isExist
	 * @description sprawdza czy warstwa o podanym id istnieje
	 * @param {text} id identyfikator warstwy
	 */
	function isOpen(id){
		if(isExist(id)){
			return $('[data-remodal-id='+id+']').remodal().getState()=='opened';
		}
		return false;
	}
	function isExist(id){
		return $('[data-remodal-id='+id+']').length ? true : false;
	}
	function destroy(id){
		try{
			$('[data-remodal-id='+id+']').remodal().destroy();
		}catch(e){}
	}
	function removeRemodals() {
		//odpowiednik dla mobile - na razie niepotrzebny
		broker.trigger('REMOVE_ALL_MODALS');
	}
	/**
	* @function generate
	* @description generuje warstę z przekazanymi danymi
	*/
	function addMessage(text){
		messages.push(text);
	}
	function showMessages() {
		if(messages.length) {
			$('#message p').html(messages.shift());
			messageModal.open();
		}
	}
	function discardMessage() {
		setTimeout(function() {
			showMessages();
		}, 2000);
	}
	function clearMessages() {
		messages = [];
	}
	function generate(params){
		var options={
			content:null,
			header:null,
			modifier:null,
			callbackOpen:null,
			callbackClose:null,
			closeOnEscape:true,
			close:true,
			show:true,
			id:null,
			overwrite:true,
			hashTracking: false,
			topLayer:false,
			buttons:{}
		};
		$.extend(true, options, params);
		if(options.overwrite && options.id){
			$('[data-remodal-id='+options.id+']').parent().remove();
		}
		var $html=$('<div class="remodal"></div>');
		if(options.id){
			$html.attr('id',options.id);
			$html.attr('data-remodal-id',options.id);
		}
		if(options.cssClass){
			$html.addClass(options.cssClass);
		}
		if(options.close){
			var closeButton=$('<button data-remodal-action="close" class="remodal-close"></button>');
			$html.append(closeButton);
		}
		if(options.header){
			$html.append('<h2>'+options.header+'</h2>');
		}
		if(options.content){
			$html.append(options.content);
		}
		if(options.buttons.length){
			var buttons=$('<div class="buttons"/>');
			$(options.buttons).each(function(i,n){
				var button=$('<button/>');
				if(n.text){
					button.text(n.text);
				}
				if(n.cssClass){
					button.addClass(n.cssClass);
				}
				if(n.click){
					button.click(function(){
						n.click();
					});
				}
				buttons.append(button).addClass('buttons-'+options.buttons.length);
			});
			$html.append(buttons);
		}
		$('body').append($html);
		var modalHandler=$html.remodal({
			hashTracking: false,
			modifier: options.modifier,
			closeOnEscape: !(options.closeOnEscape===false || options.close===false),
			closeOnOutsideClick: params.closeOnOutsideClick !== undefined ? params.closeOnOutsideClick : options.close
		});
		if(options.show){
			modalHandler.open();
		}
		if(options.callbackOpen){
			if(options.modifier=='remodal-bottom'){
				setTimeout(function(){
					html.remove('remodal-is-locked');
					html.add('remodal-bottom-view');
				},100);
			}
			options.callbackOpen.call(modalHandler);
		}
		if(options.topLayer){
			html.add('modal-top-layer')
		}else{
			html.remove('modal-top-layer');
		}
		if(options.callbackClose){
			$(document).on('closed', $html, function (e) {
				options.callbackClose();
				if(options.modifier=='remodal-bottom'){
					html.remove('remodal-bottom-view');
					$('body').css('padding-right',0);
				}
			});
		}
		return modalHandler;
	}
	function simplebarInit() {
		var $simplebar = $('.remodal-is-opened .simplebar');
		if($simplebar.length && $simplebar.data('simpleBar-init')!='true'){
			var height = $simplebar.height();
			var heightMax = parseInt($simplebar.data('height-max'));
			if(height > heightMax && heightMax>0){
				$simplebar.css('height', heightMax).simplebar();
			}
			$simplebar.data('simpleBar-init','true');
		}
	}
	function setElements(){
		alertModal=generate({
			cssClass:'alert-modal',
			id:'alert',
			show:false,
			content:'<p></p><button data-remodal-action="confirm" class="btn btn-primary">OK</button>'
		});
		$(document).on('closed', alertModal, function (e) {
			if(alertCallback){
				alertCallback();
			}
		});
		$(document).on('closed', function (e) {
			if(modalsCallbackClose[e.target.dataset.remodalId]){
				modalsCallbackClose[e.target.dataset.remodalId]();
			}
		});
		messageModal=generate({
			cssClass:'message-modal',
			id:'message',
			show:false,
			content:'<p></p><button data-remodal-action="confirm" class="btn btn-primary js-discard-message">OK</button>'
		});
	}
	function setEvents(){
		$(document).on('opened', '.remodal', function (e) {
			simplebarInit();
			//przeniesione z hotfixów logowanie pokazania modali, logika słaba
			try{
				app.logEvent({
					"id": "fancybox",
					"f03": document.location.pathname + document.location.search + document.location.hash,
					"f04": "remodal:"+$(e.target).attr('id'),
					"f05": $(e.target).find('.title').length ? $(e.target).find('.title').text().trim() : $(e.target).text().trim().substring(0,80).replace(/\s\s+/g, ' ')
				})
			}catch(e){console.log('błąd analityki modala')}
		});
		$('body').on('click','.close-modal, .js-close-modal',function(event){
			close();
		});
		$(document).on('closing', '#message', function () {
			discardMessage();
		});
	}
	function init(){
		setElements();
		setEvents();
	}
	$global('showModal', generate);
	$global('closeModal', close);
	$(document).ready(function(){
		init();
		showMessages();
	});
	$global('alert',alert);
	$global('addMessage',addMessage);
	$global('openModal',open);
	return {
		open:open,
		alert:alert,
		confirm: confirm,
		addMessage:addMessage,
		showMessages:showMessages,
		clearMessages:clearMessages,
		generate:generate,
		close:close,
		callbackClose:callbackClose,
		isOpen: isOpen,
		isExist: isExist,
		destroy: destroy
	};
});
