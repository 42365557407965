(function($) {
	/**
	 * Główna funkcja pluginu
	 *
	 * @param {object} event - Obiekt przechowujący informacje o wciśniętym klawiszu
	 */
	$.fn.select3 = function(setting) {
		return this.each(function() {
			var xhr;
			/** @type {object} $input - główny input przechowujący i przekazujący dane (ukryty dla użytkownika) */
			var $input = $(this);

			var autocomplete = $input.data("autocomplete");
			var noAjax = $input.data("noajax");
			var minChars = parseInt($input.data("minchars"));

			var $relatedInput = $("#" + $input.data("related")) || false;

			/** @type {object} $container - zawiera wszystkie inne elementy */
			var $container = $(this).parent();
			/** @type {text} $filterInput - input filtrujący listę rozwijaną */
			var $filterInput = $container.find(".select3-filter");
			var filterInputDefaultPlaceholder = $filterInput.attr('placeholder');
			/** @type {object} $selectedItemPreview - pokazuje obecnie zaznaczony element */
			var $selectedItemPreview = $container.find('.select3-selected-item');
			/** @type {object} $optionList - lista wszystkich opcji do wyboru */
			var $optionList = $container.find('.select3-option-list');
			/** @type {object} $selectedOption - obecnie zaznaczona opcja */
			var $selectedOption = $container.find('.select3-option-selected');
			/** @type {object} $highlightedOption - obecnie podświetlona opcja */
			var $hightlightedOption;
			/** @type {object} $option - pojedyncza opcja z listy */
			var $option = $container.find('.select3-option');
			/** @type {object} $firstOption - pierwsza opcja na liście */
			var $firstOption = $container.find('.select3-option:first');
			/** @type {object} $lastOption - ostatnia opcja na liście */

			var $lastOption = $(this).parent().find('.select3-option:last');
			/** @type {object} $selectionIcon - Ikona selecta */
			var $selectionIcon = $(this).parent().find('.select3-selection-icon');

			/** @type {boolean} changeSelectValueMode - zmienna sterująca trybem funkcji changeSelectValue
			 *
			 * false - domyślnie aby pozwolić na zmianę value inputa z zewnątrz
			 * true - dla operacji wewnątrz pluginu - clicku na opcji, strzałka w dół, strzałka w górę
			*/
			var changeSelectValueMode = false;
			/** @type {object} $toSelect - opcja, która ma być zaznaczona */
			var $toSelect;
			/** @type {object} $toHighlight - opcja, która ma być podświetlona */
			var $toHighlight;
			//START SCROLL
			/** @type {object} $optionListWrap - dodatkowy wrap dla listy opcji, który jest scrollowany */
			var $optionListWrap = $(this).parent().find('.select3-option-list-wrap');
			/** Obiekt z parametrami dla pluginu/scrolla
			 *
			 * DO PRZEROBIENIA
			 * optionHeight - wysokość całkowita jednej opcji
			 * visibleOptions - maksymalna ilość widocznych opcji bez scrolla
			 * numberOfOptions - całkowita ilość opcji
			 */
			var select3Settings = {};

			/** Ustawienia pluginu/scrolla */
			function setupScroll() {
				select3Settings = {
					optionHeight: $option.outerHeight(),
					visibleOptions: setting && setting.maxVisibleOptions || 13,
					numberOfOptions: setting && setting.elements ? setting.elements.length : $option.length
				};
				/** Jeżeli całkowita ilość opcji jest większa od ilości widocznych opcji - aktywuj scroll */
				if(select3Settings.numberOfOptions > select3Settings.visibleOptions ) {
					/** Dodaj klasę pluginu do przewijanego elementu
					 * Wymagane
					 */
					$optionListWrap.addClass('simplebar');
					/** Ustawienie wysokości wrapu opcji = wysokość jednej opcji * ilość widocznych opcji */
					$optionListWrap.css({
						height: function(){
							return (select3Settings.optionHeight * select3Settings.visibleOptions);
						}
					});
					/** Wywołanie pluginu scrolla */
					$optionListWrap.simplebar();
				}
			}
			function scrollBuilder() {
				var scrollOptionHeight = select3Settings.optionHeight = $option.outerHeight();
				var scrollVisibleOptions = $container.find('.select3-option:not(.select3-option-hidden)').length;
				var scrollMaxVisibleOptions = select3Settings.visibleOptions;

				if( scrollVisibleOptions <= scrollMaxVisibleOptions ){
					scrollMaxVisibleOptions = scrollVisibleOptions;
					$optionListWrap.addClass('scrollInactive');
				}
				else {
					if(scrollVisibleOptions > select3Settings.visibleOptions){
						scrollMaxVisibleOptions = select3Settings.visibleOptions;
					}
					else {
						scrollMaxVisibleOptions = scrollVisibleOptions;
					}
					$optionListWrap.removeClass('scrollInactive');
				}
				$optionListWrap = $container.find('.select3-option-list-wrap');
				$optionListWrap.css({
					height: function(){
						return (scrollOptionHeight * scrollMaxVisibleOptions);
					}
				});
				if(scrollVisibleOptions > scrollMaxVisibleOptions) {
					$optionListWrap.addClass('simplebar')
					$optionListWrap.simplebar();
				}
			}
			// KONIEC SCROLL
			function buildOptionList(setting) {
				clearOptionWrap();
				var elements = setting.elements;
				var selectedItem = setting.value;
				for(var i=0; i<elements.length;i++){
					var selectCurrent = '';
					var current = elements[i];
					if(current.value === selectedItem){
						selectCurrent = 'select3-option-selected';
					}
					$optionListWrap.append($('<div data-value="' + current.value + '" class="select3-option ' + selectCurrent + '">' + current.text + '</div>'));
				}
				dynamicElements();
			}
			function dynamicElements() {
				$selectedOption = $container.find('.select3-option-selected');
				$selectedItemPreview = $container.find('.select3-selected-item');
				/** @type {object} $option - pojedyncza opcja z listy */
				$option = $container.find('.select3-option');
			}
			/** Inicjalizacja eventów */
			function initEvents(){
				/** Click na kontenerze */
				$input.data('isInit', true);

				$filterInput.on("keyup", keypress);

				$filterInput.on("blur", function(e) {
					var $input = $(this);
					var inputValue = $input.val();
					var placeholder = $input.attr('placeholder');
					if(placeholder !== filterInputDefaultPlaceholder && placeholder !== inputValue) {
						$input.val(placeholder);
					}
					abortList(e);
				});

				$relatedInput.on("blur", function(){
					if(zipCodeIsCorrect()) $filterInput.focus();
				});

				$relatedInput.on("keyup", function(e){
					if(!$container.find(".select3-option-list").is(":empty")) clearOptionlist();
					if(zipCodeIsCorrect()) $filterInput.focus();
				})

				$container.on('click', function(event){
					clickContainer(event);
				});

				/** Click na opcji z listy */
				$container.on('click','.select3-option', function(){
					/** Funkcja wybierająca podświetloną opcję */
					selectHighlightedItemFromList();
				});
				/** Mouseenter nad opcją */
				$container.on('mouseenter','.select3-option', function(event){
					/** Funkcja podświetlająca opcję */
					highlightItemFromList(event.target);
				});

				/** Zmiana wartości inputa */
				$input.on('change', function(){
					/** Funkcja zmiany opcji/value inputa */
					changeSelectValue();
				});
				$input.on('check', function(){
					/** Funkcja zmiany opcji/value inputa */
					changeSelectValue(true);
				});

				$filterInput.on('focus', function(){
					var $input = $(this);
					var inputValue = $input.val();
					if(inputValue) {
						$input.val('');
						$input.attr('placeholder', inputValue);
					}
					if(autocomplete) {
						if($container.find(".select3-option-list").is(":empty")) {
							getList();
						} else {
							showOptionList();
						}
					}
				});

				$(document).on("click", abortList);
			}

			function addIconToSelectedItem(input, icon)  {
				if (icon) {
					input.append(`<i class="icon-${icon}" ></i>`);
				}
			}

			function updateSelectedItem(text, icon)  {
				$selectedItemPreview.text(text.trim());
				addIconToSelectedItem($selectedItemPreview, icon);
			}

			/** Funkcja wczytująca początkowe wartości */
			function loadDefaultValues(){
				$option.each(function(){
					addIconToSelectedItem($(this), $(this).data('icon'));
				});
				/** Jeśli żadna opcja nie jest domyślnie zaznaczona */
				if ( $selectedOption.length === 0 ){
					/** Ustaw wartość inputa równą wartości pierwszej opcji z listy */
					if($input.val()!==$firstOption.data('value')){
						$input.val( $firstOption.data('value') ).change();
					}
					/** Wstaw tekst pierwszej opcji z listy w miejscu pokazującym obecnie zaznaczoną opcję */
					updateSelectedItem($firstOption.text(), $firstOption.data('icon'));
					/** Zaznacz pierwszą opcję z listy */
					$firstOption.addClass('select3-option-selected');
				}
				/** Jeśli jakaś opcja jest zaznaczona np. przakazano jej atrubyt selected */
				else {
					/** Zaktualizuj wartość inputa */
					$input.val( $selectedOption.data('value') );
					/** Wstaw tekst zaznaczonej opcji z listy w miejscu pokazującym obecnie zaznaczoną opcję */
					updatePreview();
				}

				$container.on('click','.select3-option', function(){
					$input.trigger('selectOption');
				});
			}
			/** Funkcja sterująca selectem po zmianie jego value */
			function changeSelectValue(silent){
				/** False - zmiana value inputa z zewnątrz */
				if(changeSelectValueMode === false){
					/** Przechowywanie nowego value inputa po zmianie */
					var newInputValue = $input.val();
					/** Zmienna pomocnicza określająca czy nowy value istnieje dla jakiejś opcji */
					var newValueExists = false;
					/** Przeszukaj każdą opcje na liście */
					$option.each(function(){
						/** Jeśli value którejś opcji pasuje do tej w inpucie */
						if( ($(this).data('value')) == newInputValue ){
                            console.log(newInputValue);
							/** Wstaw tekst tej opcji w miejscu pokazującym obecnie zaznaczoną opcję */
							$selectedItemPreview.text( $(this).text() );
							/** Odznacz poprzednią opcję */
							$selectedOption = $container.find('.select3-option-selected');
							$selectedOption.removeClass('select3-option-selected');
							/** Zaznacz tę opcję - dodaj klasę */
							$(this).addClass('select3-option-selected');
							/** Zapisz nową opcję jako zaznaczoną */
							$selectedOption = $container.find('.select3-option-selected');
							/** Value istnieje w opcji na liście */
							newValueExists = true;
							/** Gdy znaleziono - przestań szukać i opuść each */
							return false;
						}
					});
					/** Gdy żadna opcja nie ma takiego value - zaznacz pierwszą na liście */
					if(setting && setting.defaultValueEmpty) {
						$selectedOption = $container.find('.select3-option-selected');
						$selectedOption.removeClass('select3-option-selected');
						$selectedOption = $container.find('.select3-option-selected');
						$selectedItemPreview.text(filterInputDefaultPlaceholder);
						$filterInput.attr('placeholder', filterInputDefaultPlaceholder);
					} else {
						if(!newValueExists){
							/** Wstaw tekst pierwszej opcji z listy w miejscu pokazującym obecnie zaznaczoną opcję */
							$selectedItemPreview.text( $firstOption.text() );
							/** Odznacz poprzednią opcję */
							$selectedOption = $container.find('.select3-option-selected');
							$selectedOption.removeClass('select3-option-selected');
							/** Zaznacz pierwszą opcję z listy */
							$firstOption.addClass('select3-option-selected');
							/** Zapisz nową opcję jako zaznaczoną */
							$selectedOption = $container.find('.select3-option-selected');
							/** Ustaw wartość inputa równą wartości pierwszej opcji z listy */
							if(!silent){
								$input.val( $firstOption.data('value') );
							}
						}
					}
				}
				/** Zmiana value inputa od wewnątrz - click na opcji lub Enter przy podświetlonej opcji */
				else {
					/** Nadpisz referencję obecnie zaznaczonej opcji */
					$selectedOption = $container.find('.select3-option-selected');
					/** Odznacz opcję */
					$selectedOption.removeClass('select3-option-selected');
					/** Nadpisz referencję obecnie zaznaczonej opcji */
					$selectedOption = $toSelect;
					/** Zaznacz opcję klasą */
					$selectedOption.addClass('select3-option-selected');
				}
                /** Wstaw tekst opcji w miejscu pokazującym obecnie zaznaczoną opcję */
                updatePreview();
				/** Reset zmiennej sterującej */
				changeSelectValueMode = false;
			}
			function clickContainer(event){
				if(!autocomplete) {
					/** Sprawdź czy lista obecnie klikniętego containera jest ukryta */
					if ( optionListHidden() ){
						/** Ustaw focus na inpucie, wymagane dla eventów keypress */
						showOptionList();
						/** Pokaż listę opcji */
						$filterInput.focus();
					} else {
						hideOptionList();
					}
				}
				else {
				/** Ukryj listę opcji */
					if( !$(event.target).hasClass('select3-filter') && !$(event.target).closest('.simplebar-track').length){
						hideOptionList();
					}
				}
				/** Schowaj wszystkie inne listy opcji */
				hideAllOtherOptionLists();
			}
			/** Funkcja wstawiająca tekst zaznaczonej opcji do miejsca pokazującego obecnie zaznaczoną opcję */
			function updatePreview(){
				updateSelectedItem($selectedOption.first().text(), $selectedOption.data('icon'));
                if(autocomplete) {
                    $filterInput.val($selectedOption.text().trim());
                }
			}
			/** Funkcja sprawdzająca czy lista opcji jest w tej chwili UKRYTA */
			function optionListHidden(){
				if ( $optionList.hasClass('select3-list-hidden') ) {
					/** Zwróć true - lista jest ukryta */
					return true;
				}
				else {
					/** Zwróć false - lista jest widoczna */
					return false;
				}
			}
			/** Funkcja pokazująca listę opcji */
			function showOptionList(){
				$optionList.removeClass('select3-list-hidden');
				/** Zmień kierunek strzałki rozwinięcia */
				switchArrow();
			}
			/** Funkcja ukrywająca listę opcji */
			function hideOptionList(){
				/** Jeżeli lista jest WIDOCZNA */
				if( !optionListHidden() ){
					/** Zmień kierunek strzałki rozwinięcia */
					switchArrow();
				}
				/** Ukryj listę */
				$optionList.addClass('select3-list-hidden');
				/** Wyczyść podświetlenie opcji */
				clearHighlight();
			}
			/** Funkcja zmieniająca kierunek strzałki rozwinięcia listy */
			function switchArrow() {
				$container.toggleClass('expanded');
			}
			/** Funkcja ukrywająca wszystkie inne listy opcji
			 * Tylko 1 ma być w danej chwili widoczna
			 * Warunek '.not' wykluczający obecny container jest wymagany
			 * Bez warunku click na containerze z rozwiniętą listą,
			 * schowa ją, sprawdzi czy jest schowana i znowu ją pokaże
			*/
			function hideAllOtherOptionLists(){
				//$('.select3-container').not($container).removeClass('expanded');
				$('.select3-container').not($container).find('.select3-option-list').addClass('select3-list-hidden');
			}
			/** Funkcja zaznaczająca podświetloną opcję */
			function selectHighlightedItemFromList() {
				/** Zmień wartość parametru aby uruchomić tryb operacji wewnątrz pluginu - click opcji, strzałki */
				changeSelectValueMode = true;
				/** Przekaż podświetloną opcję do zaznaczenia */
				$toSelect = $hightlightedOption;
				/** Zmień value inputa */
				if(autocomplete) {
					$input.val($toSelect.data('value') || $toSelect.text().trim() ).change();
					$filterInput.val( $toSelect.text().trim() ).change();
					$filterInput.attr('placeholder', $toSelect.text().trim());
				} else {
					$input.val( $toSelect.data('value') ).change();
				}
			}
			/** Funkcja czyszcząca podświetlenie opcji */
			function clearHighlight(){
				$('.select3-option-highlighted').removeClass('select3-option-highlighted');
				$hightlightedOption = undefined;
			}
			/**
			 * Funkcja podświetlająca opcję
			 *
			 * @param {object} element - przekazujemy funkcji element do zaznaczenia
			 */
			function highlightItemFromList(element){
				/** Usuń wcześniejsze podświetlenie */
				$('.select3-option-highlighted').removeClass('select3-option-highlighted');
				/** Zaznacz element */
				$(element).addClass('select3-option-highlighted');
				/** Zaktualizuj zmienną */
				$hightlightedOption = $(element);
				checkScroll();
			}
			/** Funkcja podświetlania następnej opcji na liście */
			function highlightNextItemFromList(event){
				/** Jesli żadna nie jest podświetlona */
				if( $hightlightedOption === undefined){
					/** Podświetl pierwszą bez klasy hidden */
					highlightItemFromList($option.filter(":not(.select3-option-hidden):first"));
				}
				else {
					/** Jeśli istnieje następna od obecnie podświetlonej bez klasy hidden */
					if( $hightlightedOption.nextAll(".select3-option:not(.select3-option-hidden)").filter(':first').length > 0){
						/** Podświetl ją */
						$toHighlight = $hightlightedOption.nextAll(".select3-option:not(.select3-option-hidden)").filter(':first');
						highlightItemFromList($toHighlight);
					}
					else {
						/** Podświetl pierwszą bez klasy hidden */
						highlightItemFromList($option.filter(":not(.select3-option-hidden):first"));
					}
				}
			}
			/** Funkcja scrollująca jeśli podświetlana opcja jest poza widoczną częścią listy */
			function checkScroll(){
				/** Wartość w pikselach o ile została zescrollowana lista */
				var currentScrollOffset = $optionListWrap.find('.simplebar-scroll-content').scrollTop();
				/** Najwyższy widoczny punkt na liście */
				var highestVisible = currentScrollOffset;
				/** Najniższy widoczny punkt na liście */
				var lowestVisible = currentScrollOffset + select3Settings.visibleOptions * select3Settings.optionHeight;
				/** Górny punkt podświetlonego elementu */
				var highlightedElementTop = $hightlightedOption.position().top + currentScrollOffset;
				/** Dolny punkt podświetlonego elementu */
				var highlightedElementBottom = $hightlightedOption.position().top + currentScrollOffset + $hightlightedOption.height();
				/**
				 * Negacja całości:
				 * Jeśli górny punkt podświetlonego elementu jest większy lub równy najwyższemu widocznemu punktowi na liście
				 * ORAZ dolny punkt jest mniejszy lub równy najniższemu widocznemu na liście - kiedy opcja jest całkowicie widoczna
				 */
				if(!(highlightedElementTop >= highestVisible && highlightedElementBottom <= lowestVisible)){
					/** Jeśli element podświetlony jest poniżej obecnego widoku listy */
					if (highlightedElementTop <= highestVisible){
						/** Ustaw scroll od góry = górny punkt podświetlonego elementu */
						$optionListWrap.find('.simplebar-scroll-content').scrollTop(function(){
							return highlightedElementTop;
						});
					}
					/** Jeśli element podświetlony jest powyżej obecnego widoku listy */
					else {
						/** Ustaw scroll od góry =
						 * Obecny scroll od góry + najniższy punkt elementu - najniższy punkt na liście
						 */
						$optionListWrap.find('.simplebar-scroll-content').scrollTop(function(){
							return $optionListWrap.find('.simplebar-scroll-content').scrollTop() + highlightedElementBottom - lowestVisible;
						});
					}
				}
			}
			/** Funkcja podświetlania poprzedniej opcji na liście */
			function highlightPreviousItemFromList(event){
				/** Jesli żadna nie jest podświetlona */
				if( $hightlightedOption === undefined){
					/** Podświetl ostatnią która nie jest hidden*/
					highlightItemFromList($option.filter(":not(.select3-option-hidden):last"));
				}
				else {
					/** Jeśli istnieje poprzednia od obecnie podświetlonej bez klasy hidden */
					if( $hightlightedOption.prevAll(".select3-option:not(.select3-option-hidden)").filter(':first').length > 0){
						/** Podświetl ją */
						$toHighlight = $hightlightedOption.prevAll(".select3-option:not(.select3-option-hidden)").filter(':first');
						highlightItemFromList($toHighlight);
					}
					else {
						/** Podświetl ostatnią bez klasy hidden */
						highlightItemFromList($option.filter(":not(.select3-option-hidden):last"));
					}
				}
			}
			/**
			 * keyup - strzałka do góry - poprzednia opcja
			 * keydown - strzałka na dół - następna opcja
			 * close - ESC - ukryj listę opcji
			 * select - ENTER - zaznacz podświetloną opcję
			 */
			function keyup(event){
				highlightPreviousItemFromList(event)
			}
			function keydown(event){
				highlightNextItemFromList(event)
			}
			function close(event){
				hideOptionList();
			}
			function select(event){
				selectHighlightedItemFromList();
				/** Brak clicku - trzeba zamknąć listę manualnie */
				hideOptionList();
			}
			function keypress(event) {
				if(!autocomplete)event.preventDefault();
				/** Strzałka do góry */
				if ( event.which === 38 ) {
					keyup(event);
					if(autocomplete) {
						/** Ustaw kursor na końcu inputa */
						$('.select3-autocomplete .select3-filter').select3.putCursorAtEnd(event);
					}
				}
				/** Strzałka na dół */
				else if ( event.which === 40 ) {
					keydown(event);
				}
				/** Wciśnięty enter */
				else if ( event.which === 13 ) {
					select(event);
					if(autocomplete){
						$filterInput.blur();
					}
				}
				/** Wciśnięty escape */
				else if ( event.which === 27 ) {
					close(event);
					if(autocomplete){
						$filterInput.blur();
					}
				}
				/** Inne klawisze */
				else {
					if(autocomplete) {
						if(noAjax){
							filterList();
						}
						else {
							xhr.abort();
							if(!$relatedInput.val().length) {
								getList();
							} else {
								filterList();
							}
						}
					} else {
						search(event);
					}
				}
			}
			function search(event){
				/** String wciśniętego klawisza w lowerCase */
				var pressedKeyString = String.fromCharCode(event.keyCode).toLowerCase();
				/** Przeszukaj wszystkie opcje */
				$option.each(function(){
					/** Weź text tej opcji, usuń spacje, weź pierwszy znak, zmień na lowercase
					 * Jeśli wynik jest równy wciśniętemu klawiszowi
					 */
					if( $(this).text().trim().charAt(0).toLowerCase() === pressedKeyString ){
						/** Podświetl tę opcję */
						highlightItemFromList($(this));
						/** Skończ each */
						return false;
					}
				});
			}
			function containsText(searchedText, searchedIn) {
				if(searchedIn.indexOf(searchedText) >= 0) {
					return true;
				} else {
					return false;
				}
			}
			function filterList() {
				if(noAjax) {
					var minimumChars = minChars || 0;
				}
				else {
					var minimumChars = minChars || 3;
				}
				if($filterInput.val().length < minimumChars && $relatedInput.val().length == 0) return
				$.each($option, function(i, val){
					var optionText = $(this).text().trim().toLowerCase();
					var filterInputValue = $filterInput.val().toLowerCase();
					/** Jeśli text opcji nie zawiera wyszukiwanej frazy */
					if(!containsText(filterInputValue, optionText)) {
						/** Ukryj opcję */
						$(this).addClass("select3-option-hidden");
					} else {
						/** Pokaż opcję */
						$(this).removeClass("select3-option-hidden");
						/** Dodaj bold do pasującej częsci tekstu */
						var val = $('.select3-filter').val();
						var text = $(this).text().trim();
						$(this).html(text.replace(new RegExp('(' + val + ')', 'gi'), "<b>$1</b>"));
					}
				});
				/** Jeśli wszystkie opcję są ukryte - brak wyników = ukryj listę */
				if($('.select3-option-hidden').length === $option.length){
					$optionList.addClass('select3-list-empty');
				}
				else {
					$optionList.removeClass('select3-list-empty');
				}
				/** Czyść podświetlenie przy każdej zmianie w wyszukiwarce */
				clearHighlight();
				scrollBuilder();
				$optionListWrap.simplebar('flashScrollbar');
			}
			/** Funkcja przesuwania kursora na koniec inputa */
			$.fn.select3.putCursorAtEnd = function(event) {
				var elements = $(event.target);
				return elements.each(function() {
					$(this).focus();
					/** Jeśli funkcja istnieje - nie działa pod IE */
					if (this.setSelectionRange) {
					/** len to miejsce, w które chcemy przesunąć kursor - dla opery musi być * 2 */
						var len = $(this).val().length * 2;
						this.setSelectionRange(len, len);
					}
					else {
						/** Zastąp wartość obecną wartością - to atutomatycznie przesuwa kursor na koniec - nie działa w Chrome */
						$(this).val($(this).val());
					}
					/** Przescrolluj na koniec jeśli textarea - dla Firefox i Chrome */
					this.scrollTop = 999999;
				});
			};
			function getList() {
				if($relatedInput.val().length > 0) {
					if(!zipCodeIsCorrect()) return;
					$filterInput.val("");
				}
				startLoading();
				xhr = $.ajax({
					method: "GET",
					contentType: "application/json",
					dataType: "text",
					url: "citiesWithZipCode.ltr",
					data: {
						q: $filterInput.val(),
						zipCode: $relatedInput.val(),
						type: "city",
					},
					success: function(response){
						listComplete(response);
						stopLoading();
					},
					error: function(jqXHR, textStatus, errorThrown) {
						if (textStatus == "abort") {
							console.log('xhr aborted')
						}
						stopLoading();
					}
				});
			}
			function listComplete(response) {
				response = JSON.parse(response);
				(response.length < 2 && $relatedInput.val().length > 0) ? putResult(response) : expandList(response);
			}
			function putResult(cities) {
				clearOptionlist();
				$filterInput.val(cities[0] && cities[0].city);
                $input.val(cities[0] && cities[0].city);
				showOptionList();
			}
			function expandList(cities) {
				console.log(cities)
				$optionList.html(renderList(cities));
				showOptionList();
				dynamicElements();
				filterList();
				scrollBuilder();

				$.each($option, function(){
					var val = $filterInput.val();
					var text = $(this).text().trim();
					$(this).html(text.replace(new RegExp('(' + val + ')', 'gi'), "<b>$1</b>"));

					$(this).on('click', function(){
						if(!$relatedInput.val()){
							var that = $(this);
							$relatedInput.val( function(){
								return that.data('zipcode');
							});
						}
					});
				});
			}
			function renderList(list) {
				var preparedList = document.createElement('div');
				$(preparedList).addClass("select3-option-list-wrap");
				$.each(list, function(i, val) {
					$(preparedList).append($("<div />", { class:"select3-option", text: val.city, 'data-zipcode': val.zipCode }));
				});

				return preparedList;
			}
			function clearOptionlist() {
				$container.find(".select3-option-list").html("");
			}
			function clearOptionWrap() {
				$container.find(".select3-option-list-wrap").html("");
			}
			function startLoading() {
				$container.addClass("select3-unactive");
				$container.addClass("select3-loading");
			}
			function stopLoading() {
				$container.removeClass("select3-unactive");
				$container.removeClass("select3-loading");
			}
			function zipCodeIsCorrect() {
				var isValid = false;
				var digits = $relatedInput.val().replace(/\D/g, '');
				if(digits.length == 5) {
					isValid = true;
					digits = digits.splice(2,0,'-');
					$relatedInput.val(digits);
				}
				return isValid;
			}
			function abortList(event) {
				if( !$(event.target).closest('.select3-container').length && !$(event.target).closest('.simplebar-track').length && !$filterInput.is(":focus")) {
					if(xhr && xhr.readystate != 4){
						xhr.abort();
					}
					hideOptionList();
					stopLoading();
				}
			}
			function enable(){
				$container.removeClass('select3-disable');
			}
			function disable(){
				$container.addClass('select3-disable');
			}
			if( typeof(setting) === 'object') {
				buildOptionList(setting);
				setupScroll();
				loadDefaultValues();
				if(!$input.data('isInit')) {
					initEvents();
				}
			}else if( typeof(setting) === 'string') {
				if(setting==='disable'){
					disable()
				}else if(setting==='enable'){
					enable();
				}else if(setting==='close'){
					close();
				}
			} else if(!$input.data('isInit')){
				setupScroll();
				initEvents();
				if(autocomplete) {
					if(!noAjax){
						clearOptionlist();
					}
				} else {
					loadDefaultValues();
				}
			}
		});
	}
})(jQuery);
