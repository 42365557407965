app.service('localStorage',function(console){
	/*
		@constructor
		Konstruktor obiektu tablico-podobnego
		niezmiennego pomiedzy odswiezeniami przegladarki
	*/
	var enabled = false;
	function StorageArray(name) {
		var items;

		this.StorageArrayName = name;

		items = JSON.parse(getItem(name));
			if (Object.prototype.toString.call( items ) !== '[object Array]') {
				items = [];
			}

		this.push.apply(this, items);
	}

	StorageArray.prototype = [];
	/*
		Nadpisanie Mutator methods
		@tutorial https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/prototype

	*/
	var methods=['push', 'pop', 'reverse', 'shift', 'sort', 'splice', 'unshift'];

	$(methods).each(function(methodName) {
		StorageArray.prototype[methodName] = function() {
			var that = this;
			var results = Array.prototype[methodName].apply(this, arguments);

			setItem(this.StorageArrayName, JSON.stringify(Array.prototype.slice.apply(that)));

			return results;
		};
	});

	function storageArray(name) {
		if(enabled){
			var instance = new StorageArray(name);
			return instance;
		}else{
			return [];
		}
	}

	function checkExpire(name) {
		if (window.localStorage.getItem('expiresDates')) {
			const expiresCollection = JSON.parse(window.localStorage.getItem('expiresDates'));
			if(expiresCollection[name] && parseFloat(expiresCollection[name]) < Date.now()) {
				removeItem(name);
				delete expiresCollection[name];
				window.localStorage.setItem('expiresDates', JSON.stringify(expiresCollection));
			}
		}
	};

	function getItem(name){
		if(enabled) {
			checkExpire(name);
			return window.localStorage.getItem(name);
		} else {
			return null;
		};
	};

	function setExpire(name, expire) {
		if(parseFloat(expire) > 0) {
			const expireValue = parseFloat(expire) * 24*60*60*1000 + Date.now();
			const expiresCollection = JSON.parse(window.localStorage.getItem('expiresDates')) || {};
			expiresCollection[name] = expireValue;
			window.localStorage.setItem('expiresDates', JSON.stringify(expiresCollection));
		}
	};

	function setItem(name,value,expire=null){ //parametr expire podawać w dniach (np. 6 oznacza 6 dni od daty utworzenia itemu)
		if(enabled){
			setExpire(name, expire);
			window.localStorage.setItem(name,value);
		}
	};
	
	function notEmpty(name){
		return (getItem(name)!==null && getItem(name)!=='');
	}
	function empty(name){
		return (getItem(name)===null || getItem(name)==='');
	}
	function removeItem(name){
		if(enabled){
			window.localStorage.removeItem(name);
		}
	}
	function clear(){
		if(enabled){
			window.localStorage.clear();
		}
	}
	try {
		enabled = window.localStorage ? true : false;
	} catch (e) {
		console.log('Wyłączony dostęp do LOCALSTORAGE');
	}

	return {
		getItem:getItem,
		setItem:setItem,
		removeItem:removeItem,
		storageArray: storageArray,
		empty:empty,
		clear:clear
	};
});
